(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPracticeInfoDialogController', RcopiaPracticeInfoDialogController);

    RcopiaPracticeInfoDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'RcopiaPracticeInfo'];

    function RcopiaPracticeInfoDialogController ($timeout, $scope, $uibModalInstance, entity, RcopiaPracticeInfo) {
        var vm = this;

        vm.rcopiaPracticeInfo = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rcopiaPracticeInfo.id !== null) {
                RcopiaPracticeInfo.update(vm.rcopiaPracticeInfo, onSaveSuccess, onSaveError);
            } else {
                RcopiaPracticeInfo.save(vm.rcopiaPracticeInfo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:RcopiaPracticeInfosUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
