(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AllergiesController', AllergiesController);


    AllergiesController.$inject = ['chart', 'Allergies', 'CoreService', '$sessionStorage', '$uibModal', 'access',
        'ACTIONS', 'Auth', 'ConfirmationService', '$rootScope', 'RcopiaUtils', 'RcopiaApi',
        'rcopiaAccount', '$scope'];

    function AllergiesController(chart, Allergies, CoreService, $sessionStorage, $uibModal, access,
                                 ACTIONS, Auth, ConfirmationService, $rootScope, RcopiaUtils, RcopiaApi,
                                 rcopiaAccount, $scope) {
        var vm = this;
        vm.chart = chart;
        vm.rcopiaAccount = rcopiaAccount;
        vm.corporation = CoreService.getCorporation();

        vm.activeTab = $sessionStorage.activePatientTabAllergies ? $sessionStorage.activePatientTabAllergies.activeTab : 0;
        vm.name = "  ";//chart.patient.lastName + " " + chart.patient.firstName;
        vm.mrNumber = chart.mrNo;

        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.keepActiveTab = keepActiveTab;
        vm.trim = trim;
        vm.severity = severity;
        vm.launchRcopia = launchRcopia;

        function toggleItem() {
            CoreService.togglePanel('allergies');
        }

        function keepActiveTab(index) {
            $sessionStorage.activePatientTabAllergies = {activeTab: index};
            vm.activeTab = index;
        }

        loadData();

        function loadData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW, access)) {

                return;
            }

            vm.allergies = Allergies.findAllByChart({id: chart.id});
        }

        var unsubscribe = $rootScope.$on('bluebookApp:RcopiaUpdatePatient', function (event, result) {
            loadData();
        });

        $scope.$on('$destroy', unsubscribe);

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'AllergiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            reaction: null,
                            treatment: null,
                            id: null,
                            active: false,
                            chart: chart
                        };
                    }
                }
            }).result.then(function () {
                loadData()

                $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_allergies');
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'AllergiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Allergies', function (Allergies) {
                        return Allergies.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadData()

                $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_allergies');
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Allergies').result.then(function () {
                Allergies.delete({id: id}, function () {
                    loadData()

                    $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_allergies');
                });
            })
        }

        function trim(name) {
            return name ? name.trim() : "";
        }

        function severity(allergy) {
            var severity = allergy.severity ? allergy.severity.name : "";
            var reactions = _.join(_.map(allergy.reactions, function (reaction) { return reaction.name; }), ", ");

            var sr = "";

            if (severity && reactions) {
                sr = "(" + severity + ": " + reactions + ")";
            } else if (severity || reactions){
                sr = "(" + severity + reactions  + ")";
            }

            return sr;
        }

        function launchRcopia() {
            RcopiaUtils.launchRcopiaPopup(
                'manage_allergies',
                'patientMode',
                vm.chart.id
            ).result.then(function () {
                RcopiaApi.executeByChart({
                    api: 'updateAllergy',
                    chartId: vm.chart.id
                }, function (result) {
                    $rootScope.$broadcast("bluebookApp:RcopiaUpdatePatient");
                })
            })
        }
    }
})();
