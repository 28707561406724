(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DiagnosesCreateDialogController', DiagnosesCreateDialogController);

    DiagnosesCreateDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Diagnoses',
        'Icd10Search', 'chart'];

    function DiagnosesCreateDialogController($timeout, $scope, $uibModalInstance, entity, Diagnoses,
                                             Icd10Search, chart) {
        var vm = this;

        vm.save = save;
        vm.clear = clear;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.getIcd10 = getIcd10;

        init();

        function init() {
            vm.icd10 = [];

            vm.datePickerOpenStatus = {
                startDate: false,
                endDate: false
            }

            vm.endDateOptions = {
                maxDate: new Date()
            }

            vm.startDateOptions = {}

            vm.chart = chart;

            vm.diagnoses = entity;
            vm.diagnoses.chart = chart;
        }

        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diagnoses.endDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diagnoses.startDate;
            vm.endDateOptions.maxDate = new Date();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.close('dismiss');
        }

        function save() {
            vm.isSaving = true;

            Diagnoses.create(vm.diagnoses, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:diagnosesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

