(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AllergiesDialogController', AllergiesDialogController);

    AllergiesDialogController.$inject = ['entity', '$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'Allergies', 'GUIUtils', 'Reaction', 'Allergen', 'CoreService', 'Severity',
        'RcopiaAllergyGroup'];

    function AllergiesDialogController(entity, $timeout, $scope, $stateParams, $uibModalInstance,
                                       Allergies, GUIUtils, Reaction, Allergen, CoreService, Severity,
                                       RcopiaAllergyGroup) {
        var vm = this;

        vm.allergies = entity;
        vm.chart = entity.chart;
        vm.onset = GUIUtils.getOnset();
        vm.severities = Severity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.allergen = Allergen.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.reaction = Reaction.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.rcopiaAllergyGroups = [];
        vm.form = {};
        vm.showDelete = false;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.reactionDate = false;

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.confirmDelete = confirmDelete;
        vm.getRcopiaAllergyGroups = getRcopiaAllergyGroups;

        vm.currentDate = {
            maxDate: new Date()
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.allergies.id !== null) {
                Allergies.update(vm.allergies, onSaveSuccess, onSaveError);
            } else {
                Allergies.save(vm.allergies, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete() {
            Allergies.delete({id: vm.allergies.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

        function getRcopiaAllergyGroups(query) {
            if (query) {
                RcopiaAllergyGroup.filter({name: query}, function (result) {
                    vm.rcopiaAllergyGroups = result;
                });
            } else {
                vm.rcopiaAllergyGroups = [];
            }
        }
    }
})();
