(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaApiController', RcopiaApiController);

    RcopiaApiController.$inject = ['$scope', 'CoreService', 'RcopiaApi', 'GenericEntityDatatableService', '$q',
        'ConfirmationService'];

    function RcopiaApiController($scope, CoreService, RcopiaApi, GenericEntityDatatableService, $q,
                                 ConfirmationService) {
        var vm = this;

        vm.descriptor = {
            title: 'Rcopia APIs',
            entityClassHumanized: 'Rcopia APIs',
            entityStateName: 'rcopia',
            service: RcopiaApi,
            serviceMethod: 'apis',
            newAction: false,
            columns: [
                {
                    name: 'api',
                    title: 'Api',
                }
            ],
            enableSearch: false,
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getPlayAction(), {
                    name : 'Execute',
                    action: function (data) {
                        return execute(data);
                    }
                }),
            ],
        }

        function execute(data) {
            return $q(function (resolve) {
                ConfirmationService.open({
                    title: 'Restore Employee',
                    // message: 'This action will <strong>restore</strong> a deleted employee. Are you sure you want to <strong>restore</strong> this Employee?',
                    message: 'Execute api <strong>' + data.api + '</strong>',
                    actions: [
                        {
                            name: 'Execute',
                            color: 'btn btn-success',
                            icon: 'fa fa-check-circle',
                            value: 'SUCCESS'
                        },
                        {
                            name: 'Cancel',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                }).result.then(function () {
                    RcopiaApi.execute({api: data.api, corporationId: CoreService.getCorporation().id}, function () {
                        resolve();
                    });
                });
            });
        }
    }
})();
