(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPracticeInfoController', RcopiaPracticeInfoController);

    RcopiaPracticeInfoController.$inject = ['GenericEntityDatatableService', 'RcopiaPracticeInfo', 'CoreService', '$q',
        '$uibModal', 'ConfirmationService'];

    function RcopiaPracticeInfoController(GenericEntityDatatableService, RcopiaPracticeInfo, CoreService, $q,
                                       $uibModal, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;

        var params = {
            corporationId: CoreService.getCorporation().id
        }

        vm.descriptor = {
            title: 'Rcopia Practice Info',
            newButtonTitle: 'New Rcopia Practice Info',
            entityClassHumanized: 'Rcopia Practice Info',
            entityStateName: 'rcopia-practice-info',
            service: RcopiaPracticeInfo,
            serviceMethod: 'filter',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'username',
                    title: 'Username'
                },
                {
                    name: 'region',
                    title: 'Region'
                },
                {
                    name: 'vendorUsername',
                    title: 'Vendor Username'
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Rcopia Practice Info',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0;
                            }
                        })
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-practice-info/update/rcopia-practice-info-dialog.html',
                controller: 'RcopiaPracticeInfoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            name: null,
                            username: null,
                            region: null,
                            corporation: CoreService.getCorporation(),
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-practice-info/update/rcopia-practice-info-dialog.html',
                controller: 'RcopiaPracticeInfoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['RcopiaPracticeInfo', function (RcopiaPracticeInfo) {
                        return RcopiaPracticeInfo.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('RcopiaPracticeInfo').result.then(function () {
                    RcopiaPracticeInfo.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();
