(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaAccount', RcopiaAccount);

    RcopiaAccount.$inject = ['$resource'];

    function RcopiaAccount($resource) {
        var resourceUrl = 'api/rcopia-accounts/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByCorporation': {url: 'api/rcopia-accounts/by-corporation/:id', method: 'GET', isArray: true},
            'rcopiaToken': {url: 'api/rcopia-accounts/rcopia-token/:chartId/:startupScreen', method: 'GET'},
            'rcopiaLimpToken': {url: 'api/rcopia-accounts/rcopia-limp-token/:corporationId/:startupScreen', method: 'GET'},
            'account': {
                url: 'api/rcopia-accounts/account/by-corporation/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
