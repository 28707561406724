(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPrescriptionController', RcopiaPrescriptionController);

    RcopiaPrescriptionController.$inject = ['$scope', '$state', 'RcopiaPrescription', 'Auth', 'ACTIONS', 'access',
        'TAB', 'params', 'chart', 'ParseLinks', '$rootScope', 'DataTablesService', 'AlertService',
        'RcopiaUtils', '$uibModal', '$uibModalStack', 'RcopiaApi', 'PendingPdfChartReport',
        'REPORT_TYPE', 'toastr'];

    function RcopiaPrescriptionController($scope, $state, RcopiaPrescription, Auth, ACTIONS, access,
                                          TAB, params, chart, ParseLinks, $rootScope, DataTablesService, AlertService,
                                          RcopiaUtils, $uibModal, $uibModalStack, RcopiaApi, PendingPdfChartReport,
                                          REPORT_TYPE, toastr
    ) {
        var vm = this;

        vm.chart = chart;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.patientMedications = [];
        vm.rcopiaMedications = [];
        vm.selected = {};
        vm.selectAll = false;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.RCOPIA_PRESCRIPTION);
        vm.access = access;
        vm.lastTab = null;
        vm.filter = {
            status: 'PENDING'
        }

        vm.transition = transition;
        vm.search = search;
        vm.getDirections = getDirections;
        vm.getQuantity = getQuantity;
        vm.getRefills = getRefills;
        vm.getDaysSupply = getDaysSupply;
        vm.dirToPharmacy = dirToPharmacy;
        vm.doNotFillBefore = doNotFillBefore;
        vm.view = view;
        vm.history = history;
        vm.loadAll = loadAll;
        vm.launchRcopia = launchRcopia;
        vm.print = print;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_MEDICATION_VIEW, access) || !vm.active) {
                return;
            }

            RcopiaPrescription.findAllByFilter({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                status: vm.filter.status
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientMedications = data;

                // initSelected();
            }

            function onError(error) {
                AlertService.error(error.data);
            }
        }

        function reload() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function search() {
            vm.page = 1;
            transition();
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result === TAB.RCOPIA_PRESCRIPTION) {
                vm.active = true;
                loadAll();
            } else if (vm.lastTab != null && result === TAB.RCOPIA_PRESCRIPTION && vm.lastTab !== TAB.RCOPIA_PRESCRIPTION) {
                loadAll();
            }

            vm.lastTab = result;
        });

        $scope.$on('$destroy', unsubscribe);

        unsubscribe = $rootScope.$on('bluebookApp:RcopiaUpdatePatient', function (event, result) {
            reload();
        });

        $scope.$on('$destroy', unsubscribe);

        function getDirections(rcopiaPrescription) {
            return RcopiaUtils.getDirections(rcopiaPrescription, true);
        }

        function getQuantity(rcopiaPrescription) {
            return RcopiaUtils.getQuantity(rcopiaPrescription, true);
        }

        function getRefills(rcopiaPrescription) {
            return RcopiaUtils.getRefills(rcopiaPrescription, true);
        }

        function getDaysSupply(rcopiaPrescription) {
            return RcopiaUtils.getDaysSupply(rcopiaPrescription);
        }

        function dirToPharmacy(rcopiaPrescription) {
            return RcopiaUtils.dirToPharmacy(rcopiaPrescription);
        }

        function doNotFillBefore(rcopiaPrescription) {
            return RcopiaUtils.doNotFillBefore(rcopiaPrescription);
        }

        function view(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-prescription/detail/rcopia-prescription-detail.html',
                controller: 'RcopiaPrescriptionDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    rcopiaPrescription: ['RcopiaPrescription', function (RcopiaPrescription) {
                        return RcopiaPrescription.get({id: id}).$promise;
                    }],
                    signAuthorities: ['BusinessCredential', 'BUSINESS_ROLES', 'CoreService', function (BusinessCredential, BUSINESS_ROLES, CoreService) {
                        return BusinessCredential.getCredentialByRole({
                            businessRole: BUSINESS_ROLES.PHYSICIAN, id: CoreService.getCurrentFacility().id
                        }).$promise;
                    }],
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: vm.chart.id}).$promise;
                    }],
                }
            });
        }

        function history() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/rcopia-prescription/history/rcopia-prescription-history.html',
                controller: 'RcopiaPrescriptionHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom-table-fix',
                animation: false,
                size: 'xl',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function launchRcopia() {
            RcopiaUtils.launchRcopiaPopup(
                'manage_medications',
                'patientMode',
                vm.chart.id
            ).result.then(function () {
                RcopiaApi.executeByChart({
                    api: 'updatePrescription',
                    chartId: vm.chart.id
                }, function (result) {
                    $rootScope.$broadcast("bluebookApp:RcopiaUpdatePatient");
                })
            })
        }

        function print(id) {
            PendingPdfChartReport.save({
                chart: {id: vm.chart.id},
                objectId: id,
                name: "" + id,
                reportType: REPORT_TYPE.RCOPIA_PRESCRIPTION
            }, onPrintSuccess, onPrintError);
        }

        function onPrintSuccess(result) {
            toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
        }

        function onPrintError() {
            toastr.error("We couldn't generate the report.");
        }
    }
})();
