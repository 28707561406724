(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReportExecuteDetailController', ReportExecuteDetailController);

    ReportExecuteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ReportExecute', 'Report', 'ReportCondition'];

    function ReportExecuteDetailController($scope, $rootScope, $stateParams, previousState, entity, ReportExecute, Report, ReportCondition) {
        var vm = this;

        vm.reportExecute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:reportExecuteUpdate', function(event, result) {
            vm.reportExecute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
