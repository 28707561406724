(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaApi', RcopiaApi);

    RcopiaApi.$inject = ['$resource'];

    function RcopiaApi($resource) {
        var resourceUrl = 'api/rcopia-apis';

        return $resource(resourceUrl, {}, {
            'apis': {
                url: 'api/rcopia-apis/apis',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        var id = 0;

                        return _.map(data, function (api) {
                            return {id: id++, api: api}
                        })
                    }
                    return data;
                }
            },
            'execute': {url: 'api/rcopia-apis/execute/:api/:corporationId', method: 'GET', isArray: true},
            'executeByChart': {url: 'api/rcopia-apis/execute/chart/:api/:chartId', method: 'GET', isArray: true}
        });
    }
})();
