(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaMedicationDetailController', RcopiaMedicationDetailController);

    RcopiaMedicationDetailController.$inject = ['$scope', '$rootScope', 'rcopiaMedication', 'RcopiaMedication', 'Chart',
        'Employee', 'Pharmacy', 'RcopiaUtils', '$uibModalInstance', 'chart'];

    function RcopiaMedicationDetailController($scope, $rootScope, rcopiaMedication, RcopiaMedication, Chart,
                                              Employee, Pharmacy, RcopiaUtils, $uibModalInstance, chart) {
        var vm = this;

        vm.rcopiaMedication = rcopiaMedication;
        vm.chart = chart;

        vm.getDirections = getDirections;
        vm.getQuantity = getQuantity;
        vm.getRefills = getRefills;
        vm.getDaysSupply = getDaysSupply;
        vm.dirToPharmacy = dirToPharmacy;
        vm.doNotFillBefore = doNotFillBefore;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getDirections(rcopiaMedication) {
            return RcopiaUtils.getDirections(rcopiaMedication);
        }

        function getQuantity(rcopiaMedication) {
            return RcopiaUtils.getQuantity(rcopiaMedication);
        }

        function getRefills(rcopiaMedication) {
            return RcopiaUtils.getRefills(rcopiaMedication);
        }

        function getDaysSupply(rcopiaMedication) {
            return RcopiaUtils.getDaysSupply(rcopiaMedication);
        }

        function dirToPharmacy(rcopiaMedication) {
            return RcopiaUtils.dirToPharmacy(rcopiaMedication);
        }

        function doNotFillBefore(rcopiaMedication) {
            return RcopiaUtils.doNotFillBefore(rcopiaMedication);
        }
    }
})();
