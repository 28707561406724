(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaAccountDialogController', RcopiaAccountDialogController);

    RcopiaAccountDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'RcopiaAccount', 'CoreService', 'Employee'];

    function RcopiaAccountDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                            RcopiaAccount, CoreService, Employee) {
        var vm = this;

        vm.rcopiaAccount = entity;
        vm.employees = [];
        vm.accountTypes = [
            'ANY',

            'CHIROPRACTOR',

            'DENTIST',

            'NURSE_PRACTITIONER',

            'PART_TIME_PHYSICIAN',

            'PHYSICIAN',

            'PHYSICIAN_ASSISTANT',

            'RESIDENT',

            'STAFF',

            'VENDOR'
        ];

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rcopiaAccount.id !== null) {
                RcopiaAccount.update(vm.rcopiaAccount, onSaveSuccess, onSaveError);
            } else {
                RcopiaAccount.save(vm.rcopiaAccount, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:rcopiaAccountUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            var filter = {page: 0, size: 20, query: query, corporationId: CoreService.getCorporation().id};

            Employee.findAllWithoutRcopiaAccount(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();
