(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaUtils', RcopiaUtils);

    RcopiaUtils.$inject = ['$uibModal'];

    function RcopiaUtils($uibModal) {

        var service = {
            getDirections: getDirections,
            getQuantity: getQuantity,
            getRefills: getRefills,
            getDaysSupply: getDaysSupply,
            dirToPharmacy: dirToPharmacy,
            doNotFillBefore: doNotFillBefore,
            launchRcopiaPopup: launchRcopiaPopup
        };

        return service;

        function getDirections(rcopiaPrescription, clear) {
            return _.join(_.map(rcopiaPrescription.sig.dosage.instructions, function (instruction) {
                return getDirections2(instruction, clear);
            }), " ");
        }

        function getDirections2(rcopiaPrescription, clear) {
            if (clear) {
                return clearString(rcopiaPrescription.action) + " " +
                    clearString(rcopiaPrescription.dose) + " " +
                    clearString(rcopiaPrescription.doseUnit) + " " +
                    clearString(rcopiaPrescription.route) + " " +
                    clearString(rcopiaPrescription.doseTiming) + " " +
                    (clearString(rcopiaPrescription.doseOther) + " ") +
                    (clearString(rcopiaPrescription.patientNotes));
            } else {
                return clearString(rcopiaPrescription.action) + " " +
                    (rcopiaPrescription.dose ?
                        ("**" + rcopiaPrescription.dose + "** " + "(" + numberToWords.toWords(rcopiaPrescription.dose) + ") ") : "") +
                    clearString(rcopiaPrescription.doseUnit) + " " +
                    clearString(rcopiaPrescription.route) + " " +
                    clearString(rcopiaPrescription.doseTiming) + " " +
                    (clearString(rcopiaPrescription.doseOther) + " ") +
                    (clearString(rcopiaPrescription.patientNotes));
            }
        }

        function getQuantity(rcopiaPrescription, clear) {
            if (clear) {
                return clearString(rcopiaPrescription.sig.quantity) + " " + clearString(rcopiaPrescription.sig.quantityUnit);
            } else {
                return (rcopiaPrescription.sig.quantity ? ("**" + rcopiaPrescription.sig.quantity + "** " + "(" + numberToWords.toWords(rcopiaPrescription.sig.quantity) + ") ") : "") +
                    clearString(rcopiaPrescription.sig.quantityUnit);
            }
        }

        function getRefills(rcopiaPrescription, clear) {
            if (clear) {
                return rcopiaPrescription.sig.refills === '0' || rcopiaPrescription.sig.refills === 0 ? 'none' : rcopiaPrescription.sig.refills;
            } else {
                return (rcopiaPrescription.sig.refills === '0' || rcopiaPrescription.sig.refills === 0 ? 'none' : "**" + rcopiaPrescription.sig.refills + "**" + " (" + numberToWords.toWords(rcopiaPrescription.sig.refills) + ")");
            }
        }

        function getDaysSupply(rcopiaPrescription) {
            return rcopiaPrescription.sig.duration ? (rcopiaPrescription.sig.duration + " days") : "";
        }

        function dirToPharmacy(rcopiaPrescription) {
            return rcopiaPrescription.sig.otherNotes ? (rcopiaPrescription.sig.otherNotes) : "";
        }

        function doNotFillBefore(rcopiaPrescription) {
            return clearString(rcopiaPrescription.sig.effectiveDate);
        }

        function clearString(value) {
            return value ? value : "";
        }

        function launchRcopiaPopup(startupScreen, mode, chartId) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia/popup/rcopia-popup.html',
                controller: 'RcopiaPopupController',
                controllerAs: 'vm',
                backdrop: true,
                keyboard: false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    options: {
                        startupScreen: startupScreen,
                        mode: mode,
                        chartId: chartId
                    }
                }
            });
        }
    }
})();
