(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .controller('ChartCreateDialogController', ChartCreateDialogController);

    ChartCreateDialogController.$inject = ['$uibModalInstance', 'facility', 'chart', 'ParseLinks',
        'CoreService', 'CountryState', 'filter', 'DateUtils', 'Insurance', 'TypeLevelCare', 'Bed',
        'TypePaymentMethods', 'TypeMaritalStatus', 'TypeRace', 'TypeEthnicity', 'GUIUtils',
        'ContactsFacility', 'TypeDischargeType', 'WizardHandler', 'InsuranceCarrier',
        'InsuranceType', 'InsuranceRelation', 'InsuranceCarrierSearch', 'AlertService', 'Chart',
        'Employee', '$state', 'Track', 'copyChart', 'InsurancePicture', 'InsuranceBackPicture',
        'ChartPictureLicense', 'ChartPicture', 'Pharmacy', 'FacilityPharmacy'];

    function ChartCreateDialogController($uibModalInstance, facility, chart, ParseLinks, CoreService,
                                         CountryState, filter, DateUtils, Insurance, TypeLevelCare, Bed,
                                         TypePaymentMethods, TypeMaritalStatus, TypeRace, TypeEthnicity, GUIUtils,
                                         ContactsFacility, TypeDischargeType, WizardHandler, InsuranceCarrier,
                                         InsuranceType, InsuranceRelation, InsuranceCarrierSearch, AlertService, Chart,
                                         Employee, $state, Track, copyChart, InsurancePicture, InsuranceBackPicture,
                                         ChartPictureLicense, ChartPicture, Pharmacy, FacilityPharmacy) {

        var vm = this;

        vm.loadBeds = loadBeds;
        vm.getContactsFacility = getContactsFacility;
        vm.getInsuranceCarrier = getInsuranceCarrier;
        vm.finishedWizard = finishedWizard;
        vm.cancelledWizard = cancelledWizard;
        vm.cancel = cancel;
        vm.save = save;
        vm.isLastStep = isLastStep;
        vm.openCalendar = openCalendar;
        vm.getFilter = getFilter;
        vm.searchByFilter = searchByFilter;
        vm.clearFilter = clearFilter;
        vm.next = next;
        vm.activateLeftArrow = activateLeftArrow;
        vm.activateStep = activateStep;
        vm.loadChart = loadChart;
        vm.disableWizard = disableWizard;
        vm.clearPrimaryInsurance = clearPrimaryInsurance;
        vm.removeSecondaryInsurance = removeSecondaryInsurance;
        vm.addSecondaryInsurance = addSecondaryInsurance;
        vm.setValidationClass = setValidationClass;
        vm.carrierTagTransform = carrierTagTransform;
        vm.filterTracks = filterTracks;
        vm.checkField = checkField;
        vm.getMarketers = getMarketers;
        vm.getAdmissionReps = getAdmissionReps;
        vm.getPharmacies = getPharmacies;

        init();

        function init() {
            vm.data = null;
            vm.copyChart = copyChart;
            vm.typeLevelCare = [];
            vm.paymentMethod = [];

            vm.facility = facility;
            vm.filter = filter;
            vm.filterTpl = angular.copy(filter);

            vm.corporation = CoreService.getCorporation();

            vm.page = 1;
            vm.charts = [];
            vm.countChartsByFacilities = [];
            vm.itemsPerPage = 5;

            vm.patientPictureDefault = CoreService.patientPictureDefault();
            vm.genericCardImageFront = CoreService.insuranceFrontPictureDefault();
            vm.genericCardImageBack = CoreService.insuranceBackPictureDefault();

            vm.carriers = [];

            vm.datePickerOpenStatus = {
                admissionDate: false,
                dateBirth: false
            }
            vm.admissionDate = {};
            vm.birthDateOptions = {
                maxDate: new Date(),
                minDate: new Date(1900, 1, 1)
            };

            vm.disabled = true;
            vm.showChartList = false;
            vm.allowEditBasicInfo = true;

            vm.getMarketers();
            vm.getAdmissionReps();

            TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                vm.typeLevelCare = result;

                if (copyChart && copyChart.typeLevelCare) {
                    var typeLevelCare = _.filter(vm.typeLevelCare, {name: copyChart.typeLevelCare.name});
                    vm.chart.typeLevelCare = typeLevelCare && typeLevelCare.length > 0 ? typeLevelCare[0] : null;
                }
            });
            vm.gender = GUIUtils.getGender();
            vm.race = TypeRace.byCorporation(CoreService.getFilterByCurrentCorporation());
            vm.ethnicity = TypeEthnicity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
            vm.maritalStatus = TypeMaritalStatus.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
            TypePaymentMethods.byFacility(CoreService.getFilterByFacility(vm.facility), function (result) {
                vm.paymentMethod = result;

                if (copyChart && copyChart.typePaymentMethods) {
                    var paymentMethod = _.filter(vm.paymentMethod, {name: copyChart.typePaymentMethods.name});
                    vm.chart.typePaymentMethods = paymentMethod && paymentMethod.length > 0 ? paymentMethod[0] : null;
                }
            });
            vm.insuranceCarriers = [];
            vm.insuranceTypes = InsuranceType.byCorporation(CoreService.getFilterByCurrentCorporation());
            vm.insuranceRelations = InsuranceRelation.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
            vm.states = CountryState.query();
            vm.typeDischargeType = TypeDischargeType.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

            if (copyChart) {
                loadChart(copyChart.id);
            }
            vm.pharmacies = getPharmacies();

        }

        function loadChart(id) {
            vm.chart = {
                allowReadmit: true,
                admissionDate: new Date(),
                status: 'WAITING',
                facility: facility
            }

            vm.allowEditBasicInfo = true;

            if (id) {
                Chart.get({id: id}, function (data) {
                    vm.data = data;
                    vm.allowEditBasicInfo = false;
                    vm.disabled = data.typePaymentMethods == null || data.typePaymentMethods.category !== 'Insurance';

                    vm.chart.firstName = data.firstName;
                    vm.chart.lastName = data.lastName;
                    vm.chart.middleName = data.middleName;
                    vm.chart.preferredName = data.preferredName;
                    vm.chart.sex = data.sex;
                    vm.chart.typeRace = data.typeRace;
                    vm.chart.social = data.social;
                    vm.chart.dateBirth = data.dateBirth;
                    vm.chart.sobrietyDate = data.sobrietyDate;
                    vm.chart.firstContactName = data.firstContactName;
                    vm.chart.firstContactPhone = data.firstContactPhone;
                    vm.chart.firstContactRelationship = data.firstContactRelationship;
                    vm.chart.typeLevelCare = data.typeLevelCare;
                    vm.chart.dateFirstContact = data.dateFirstContact;
                    vm.chart.dateBirth = data.dateBirth;
                    vm.chart.oneTimeOnly = data.oneTimeOnly;
                    vm.chart.externalId = data.externalId;
                    vm.chart.address = data.address;
                    vm.chart.addressTwo = data.addressTwo;
                    vm.chart.city = data.city;
                    vm.chart.countryState = data.countryState;
                    vm.chart.zip = data.zip;
                    vm.chart.phone = data.phone;
                    vm.chart.altPhone = data.altPhone;
                    vm.chart.email = data.email;
                    vm.chart.typePaymentMethods = data.typePaymentMethods;
                    vm.chart.typeEthnicity = data.typeEthnicity;
                    vm.chart.typeMaritalStatus = data.typeMaritalStatus;
                    vm.chart.referrer = data.referrer;
                    vm.chart.dischargeDate = null;
                    vm.chart.dischargeTo = null;
                    vm.chart.typeDischargeType = null;
                    vm.chart.occupancy = data.occupancy;
                    vm.chart.employer = data.employer;
                    vm.chart.refererRequireContact = data.refererRequireContact;
                    vm.chart.sobriety = data.sobriety;
                    vm.chart.employerPhone = data.employerPhone;
                    vm.chart.patientId = copyChart ? null : data.patientId;
                    vm.chart.defaultPharmacy = copyChart ? null : data.defaultPharmacy;

                    loadPictures(data);

                    loadInsurances(data);

                    loadBeds();
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            }
        }

        function getPharmacies(query) {
            if (query && query.length > 2) {
                Pharmacy.findAllByFilter({searchQuery: query}, function (result) {
                    vm.pharmacies = result;
                });
            } else {
                FacilityPharmacy.findAllByFilter({facilityId: CoreService.getCurrentFacility().id}, function (result) {
                    vm.pharmacies = [];
                        result.forEach((fP => {
                            vm.pharmacies.push(fP.pharmacy)
                        }))
                });
            }
        }

        function save() {
            vm.isSaving = true;

            if (vm.chart.primary) {
                vm.chart.primary.insuranceOrder = 1;
            }

            if (vm.chart.secondary) {
                vm.chart.secondary.insuranceOrder = 2;
            }

            Chart.create(vm.chart,
                function (result) {
                    $uibModalInstance.close(result);
                }, function () {
                    vm.isSaving = false;
                });
        }

        function disableWizard() {
            vm.disabled = vm.chart.typePaymentMethods == null || vm.chart.typePaymentMethods.category !== 'Insurance';

            if (vm.disabled) {
                vm.chart.primary = null;
                vm.chart.secondary = null;
            }

            return vm.disabled;
        }

        function loadBeds() {
            Bed.findAllActive({gender: vm.chart.sex, id: vm.facility.id}, function (result) {
                if (result) {
                    vm.beds = result;
                }
            });
        }

        function getContactsFacility(query) {
            var filter = {page: 0, size: 10, searchQuery: query, facilityId: vm.facility.id};

            return ContactsFacility.filter(filter).$promise;
        }

        function getInsuranceCarrier(query) {
            if (query) {
                if (query.length > 2) {
                    query = _.lowerCase(query);
                    InsuranceCarrierSearch.query({
                        query: query
                    }, function (result) {
                        if (result.length == 0) {
                            vm.carriers = [carrierTagTransform(query)];
                        } else {
                            vm.carriers = result;
                        }
                    });
                }
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function finishedWizard() {

        }

        function cancelledWizard() {

        }

        function isLastStep() {
            var totalSteps = WizardHandler.wizard('createChartWizard').totalStepCount(),
                currentStep = WizardHandler.wizard('createChartWizard').currentStepNumber();
            return totalSteps == currentStep;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function searchByFilter() {
            vm.page = 1;
            vm.getFilter();
            countByFilter();
        }

        function countByFilter() {
            var filter = angular.copy(vm.filter);

            vm.countFilterStr = "";

            if ((!filter.firstName || filter.firstName === '') &&
                (!filter.middleName || filter.middleName === '') &&
                (!filter.lastName || filter.lastName === '') &&
                (!filter.preferredName || filter.preferredName === '') &&
                (!filter.social || filter.social === '') &&
                (!filter.dob || filter.dob === '')) {
                return;
            }

            filter.statuses = ['CURRENT', 'ARCHIVE', 'CLOSED'];
            filter.corporationId = vm.corporation.id;

            vm.showChartList = true;

            if (filter.dob) {
                filter.dob = DateUtils.convertLocalDateToServer(filter.dob);
            } else {
                checkVoidParam(filter.dob);
            }

            checkVoidParam(filter.firstName);
            checkVoidParam(filter.middleName);
            checkVoidParam(filter.lastName);
            checkVoidParam(filter.preferredName);
            checkVoidParam(filter.social);

            Chart.countByFacilities(filter, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.countChartsByFacilities = data;
                var total = 0;
                if (vm.countChartsByFacilities.length > 0) {
                    vm.countFilterStr +=
                        _.join(
                            _.map(vm.countChartsByFacilities, function (counter) {
                                total += counter.count;
                                return counter.count + " in " + counter.name;
                            }), ', ');
                    vm.countFilterStr = "Found " + total + " patients matching this criteria (" + vm.countFilterStr;
                    vm.countFilterStr += "). Please see the list below for the once in this facility.";
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clearFilter() {
            vm.filter = angular.copy(vm.filterTpl);
        }

        function getFilter() {
            var filter = angular.copy(vm.filter);

            filter.statuses = ['ARCHIVE', 'CLOSED'];
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.facilityId = vm.facility.id;

            vm.showChartList = true;

            if (filter.dob) {
                filter.dob = DateUtils.convertLocalDateToServer(filter.dob);
            } else {
                checkVoidParam(filter.dob);
            }

            checkVoidParam(filter.firstName);
            checkVoidParam(filter.middleName);
            checkVoidParam(filter.lastName);
            checkVoidParam(filter.preferredName);
            checkVoidParam(filter.social);

            Chart.filterLastClosedOrArchive(filter, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function checkVoidParam(param) {
            if (!param || param === '') {
                param = null;
            }
        }

        function activateStep(item) {
            if (item == null) {
                loadChart();
                WizardHandler.wizard('createChartWizard').next();
            } else if (!item.allowReadmit) {
                WizardHandler.wizard('createChartWizard').cancel();
            } else {
                vm.loadChart(item.id);
                WizardHandler.wizard('createChartWizard').next();
            }
        }

        function next(value) {
            if (value == 0) {
                vm.page++;
                vm.getFilter();
            } else {
                vm.page--;
                vm.getFilter();
            }
        }

        function activateLeftArrow() {
            return vm.totalItems / vm.itemsPerPage > vm.page
        }

        function loadInsurancePrimaryPictures(insuranceId) {
            InsurancePicture.getOriginalBase64ByInsurance({id: insuranceId}, function (result) {
                vm.chart.primaryInsuranceFrontPictureOriginal = result.image;
            });

            InsuranceBackPicture.getOriginalBase64ByInsurance({id: insuranceId}, function (result) {
                vm.chart.primaryInsuranceBackPictureOriginal = result.image;
            });
        }

        function loadInsuranceSecondaryPictures(insuranceId) {
            InsurancePicture.getOriginalBase64ByInsurance({id: insuranceId}, function (result) {
                vm.chart.secondaryInsuranceFrontPictureOriginal = result.image;
            });

            InsuranceBackPicture.getOriginalBase64ByInsurance({id: insuranceId}, function (result) {
                vm.chart.secondaryInsuranceBackPictureOriginal = result.image;
            });
        }

        function clearPrimaryInsurance() {
            vm.chart.primary = null

            vm.chart.primary = vm.chart.secondary;

            vm.chart.primaryInsuranceFrontPicture = vm.chart.secondaryInsuranceFrontPicture;
            vm.chart.primaryInsuranceFrontPictureOriginal = vm.chart.secondaryInsuranceFrontPictureOriginal;
            vm.chart.primaryInsuranceBackPicture = vm.chart.secondaryInsuranceBackPicture;
            vm.chart.primaryInsuranceBackPictureOriginal = vm.chart.secondaryInsuranceBackPictureOriginal;

            vm.chart.secondary = null;
            vm.chart.secondaryInsuranceFrontPicture = null;
            vm.chart.secondaryInsuranceFrontPictureOriginal = null;
            vm.chart.secondaryInsuranceBackPicture = null;
            vm.chart.secondaryInsuranceBackPictureOriginal = null;

            clearPictures();
        }

        function removeSecondaryInsurance() {
            WizardHandler.wizard('createChartWizard').previous();

            vm.chart.secondary = null;

            vm.chart.secondaryInsuranceFrontPicture = null;
            vm.chart.secondaryInsuranceFrontPictureOriginal = null;
            vm.chart.secondaryInsuranceBackPicture = null;
            vm.chart.secondaryInsuranceBackPictureOriginal = null;

            clearPictures();
        }

        function addSecondaryInsurance() {
            vm.chart.secondary = {
                insuranceOrder: 2
            };
        }

        function clearPictures() {
            if (vm.chart.primary == null) {
                vm.chart.primaryInsuranceFrontPicture = null;
                vm.chart.primaryInsuranceFrontPictureOriginal = null;
                vm.chart.primaryInsuranceBackPicture = null;
                vm.chart.primaryInsuranceBackPictureOriginal = null;
            }

            if (vm.chart.secondary == null) {
                vm.chart.secondaryInsuranceFrontPicture = null;
                vm.chart.secondaryInsuranceFrontPictureOriginal = null;
                vm.chart.secondaryInsuranceBackPicture = null;
                vm.chart.secondaryInsuranceBackPictureOriginal = null;
            }
        }

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function carrierTagTransform(newTag) {
            return {
                id: null,
                acctno: newTag,
                name: newTag
            };
        }

        function filterTracks(searchQuery) {
            if (searchQuery != null) {
                var filter = {
                    page: 0,
                    size: 20,
                    facilityId: vm.facility.id,
                    searchQuery: vm.searchQuery,
                    enabled: true
                };

                Track.filter(filter, function (result) {
                    vm.tracks = result;
                });
            }
        }

        function loadPictures(chart) {
            ChartPictureLicense.getOriginalBase64ByChart({id: chart.id}, function (result) {
                vm.chart.patientLicenseOriginal = result.image;
            });

            ChartPicture.getOriginalBase64ByChart({id: chart.id}, function (result) {
                vm.chart.pictureRefOriginal = result.image;
            });
        }

        function loadInsurances(chart) {
            if (chart.primary) {
                Insurance.get({id: chart.primary.id}, function (insurance) {
                    var insuranceId = insurance.id;

                    insurance.id = null;

                    vm.chart.primary = insurance;
                    loadInsurancePrimaryPictures(insuranceId);
                });
            }

            if (chart.secondary) {
                Insurance.get({id: chart.secondary.id}, function (insurance) {
                    var insuranceId = insurance.id;

                    insurance.id = null;

                    vm.chart.secondary = insurance;
                    loadInsuranceSecondaryPictures(insuranceId);
                });
            }
        }

        function checkField(field) {
            return CoreService.enableByFacilityType(vm.facility, field);
        }

        function getMarketers(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.marketer = result;
            });
        }

        function getAdmissionReps(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.admissionReps = result;
            });
        }
    }
})();
