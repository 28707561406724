(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Rcopia', Rcopia);

    Rcopia.$inject = ['$resource'];

    function Rcopia ($resource) {
        var resourceUrl =  'api/rcopia';

        return $resource(resourceUrl, {}, {
            'sendPatient': {
                url: 'api/rcopia/send-patient/:id',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'updatePatient': {url: 'api/rcopia/update-patient/:id', method: 'GET', isArray: true},
            'getNotificationCount': {url: 'api/rcopia/get-notification-count/:corporationId', method: 'GET', isArray: true}
        });
    }
})();
