(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaDrugController', RcopiaDrugController);

    RcopiaDrugController.$inject = ['GenericEntityDatatableService', '$scope', '$state', 'RcopiaDrug'];

    function RcopiaDrugController (GenericEntityDatatableService, $scope, $state, RcopiaDrug) {
        var vm = this;

        vm.descriptor = {
            title: 'Rcopia Drugs',
            entityClassHumanized: 'Rcopia Drugs',
            entityStateName: 'rcopia-drug',
            service: RcopiaDrug,
            serviceMethod: 'findAllByFilter',
            pagination: 'remote',
            options: [
                {key: 'aaSorting', value: [[0, 'rcopiaId']]}
            ],
            newAction: false,
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            columns: [
                {
                    name: 'rcopiaId',
                    title: 'Rcopia ID',
                },
                {
                    name: 'ndcid',
                    title: 'Ndcid',
                },
                {
                    name: 'firstDataBankMedID',
                    title: 'First Data Bank Med ID',
                },
                {
                    name: 'rxnormID',
                    title: 'Rxnorm ID',
                },
                {
                    name: 'brandName',
                    title: 'Brand Name',
                },
                {
                    name: 'genericName',
                    title: 'Generic Name',
                },
                {
                    name: 'form',
                    title: 'Form',
                },
                {
                    name: 'strength',
                    title: 'Strength',
                },
                {
                    name: 'schedule',
                    title: 'Schedule',
                },
                {
                    name: 'supply',
                    title: 'Supply',
                },
                {
                    name: 'compound',
                    title: 'Compound',
                }
            ]
        }
    }
})();
