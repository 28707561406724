(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .constant('AUTH_ITEM_TYPE', {
            'TYPE_ROLE': 1,
            'TYPE_RESOURCE': 2,
            'TYPE_ACTION': 3
        })
        .constant('ROLES', {
            'ROLE_USER': 'ROLE_USER',
            'ROLE_ADMIN': 'ROLE_ADMIN',
            'ROLE_SYSTEM_ADMIN': 'ROLE_SYSTEM_ADMIN',
            'ROLE_SYSTEM': 'ROLE_SYSTEM'
        })
        .constant('RESOURCES', {
            'RESOURCE_MAIN_MENU': 'RESOURCE_MAIN_MENU'
        })
        .constant('ACTIONS', {
            'ACTION_MAIN_MENU_DASHBOARD': 'ACTION_MAIN_MENU_DASHBOARD',
            'ACTION_MAIN_MENU_PATIENT': 'ACTION_MAIN_MENU_PATIENT',
            'ACTION_MAIN_MENU_GROUP_SESSIONS': 'ACTION_MAIN_MENU_GROUP_SESSIONS',
            'ACTION_MAIN_MENU_BED_SHEET': 'ACTION_MAIN_MENU_BED_SHEET',
            'ACTION_MAIN_MENU_INCIDENT_REPORTS': 'ACTION_MAIN_MENU_INCIDENT_REPORTS',
            'ACTION_MAIN_MENU_OUTREACH_CAMPAIGNS': 'ACTION_MAIN_MENU_OUTREACH_CAMPAIGNS',
            'ACTION_MAIN_MENU_TELEHEALTH': 'ACTION_MAIN_MENU_TELEHEALTH',
            //Nursing submenus.
            'ACTION_MAIN_MENU_MEDICAL_NURSING': 'ACTION_MAIN_MENU_MEDICAL_NURSING',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_FAX': 'ACTION_MAIN_MENU_MEDICAL_NURSING_FAX',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_REQUISITIONS': 'ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_REQUISITIONS',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_RESULTS': 'ACTION_MAIN_MENU_MEDICAL_NURSING_LAB_RESULTS',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_MARS': 'ACTION_MAIN_MENU_MEDICAL_NURSING_MARS',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_NOTING': 'ACTION_MAIN_MENU_MEDICAL_NURSING_NOTING',
            'ACTION_MAIN_MENU_MEDICAL_NURSING_PHYSICIAN_REVIEW': 'ACTION_MAIN_MENU_MEDICAL_NURSING_PHYSICIAN_REVIEW',

            'ACTION_MAIN_MENU_SHIFT': 'ACTION_MAIN_MENU_SHIFT',
            'ACTION_MAIN_MENU_CONTACTS': 'ACTION_MAIN_MENU_CONTACTS',
            'ACTION_MAIN_MENU_AUDIT': 'ACTION_MAIN_MENU_AUDIT',
            'ACTION_MAIN_MENU_REPORTS': 'ACTION_MAIN_MENU_REPORTS',
            //Templates submenus.
            'ACTION_MAIN_MENU_TEMPLATES': 'ACTION_MAIN_MENU_TEMPLATES',
            'ACTION_MAIN_MENU_TEMPLATES_ASSISTANT': 'ACTION_MAIN_MENU_TEMPLATES_ASSISTANT',
            'ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY': 'ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY',
            'ACTION_MAIN_MENU_TEMPLATES_CARE_COORDINATION': 'ACTION_MAIN_MENU_TEMPLATES_CARE_COORDINATION',
            'ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS': 'ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS',
            'ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL': 'ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL',
            'ACTION_MAIN_MENU_TEMPLATES_OCCUPANCY': 'ACTION_MAIN_MENU_TEMPLATES_OCCUPANCY',
            'ACTION_MAIN_MENU_TEMPLATES_REPORTS_TEMPLATES': 'ACTION_MAIN_MENU_TEMPLATES_REPORTS_TEMPLATES',
            'ACTION_MAIN_MENU_TEMPLATES_PATIENT_MEDICATION_TEMPLATE': 'ACTION_MAIN_MENU_TEMPLATES_PATIENT_MEDICATION_TEMPLATE',
            'ACTION_MAIN_MENU_TEMPLATES_INCIDENT_REPORT_NOTE_TEMPLATE': 'ACTION_MAIN_MENU_TEMPLATES_INCIDENT_REPORT_NOTE_TEMPLATE',
            'ACTION_MAIN_MENU_TEMPLATES_CAMPAIGN': 'ACTION_MAIN_MENU_TEMPLATES_CAMPAIGN',
            'ACTION_MAIN_MENU_TEMPLATES_FACESHEET_TEMPLATE': 'ACTION_MAIN_MENU_TEMPLATES_FACESHEET_TEMPLATE',
            'ACTION_MAIN_MENU_TEMPLATES_FACESHEET_SECTION': 'ACTION_MAIN_MENU_TEMPLATES_FACESHEET_SECTION',
            'ACTION_MAIN_MENU_TEMPLATES_TRACK': 'ACTION_MAIN_MENU_TEMPLATES_TRACK',
            'ACTION_MAIN_MENU_TEMPLATES_EVALUATION_AUDIT': 'ACTION_MAIN_MENU_TEMPLATES_EVALUATION_AUDIT',
            //Config submenus.
            'ACTION_MAIN_MENU_CONFIG': 'ACTION_MAIN_MENU_CONFIG',
            'ACTION_MAIN_MENU_CONFIG_ACTIONS': 'ACTION_MAIN_MENU_CONFIG_ACTIONS',
            'ACTION_MAIN_MENU_CONFIG_ACUITY': 'ACTION_MAIN_MENU_CONFIG_ACUITY',
            'ACTION_MAIN_MENU_CONFIG_ALLERGEN': 'ACTION_MAIN_MENU_CONFIG_ALLERGEN',
            'ACTION_MAIN_MENU_CONFIG_BED': 'ACTION_MAIN_MENU_CONFIG_BED',
            'ACTION_MAIN_MENU_CONFIG_BUILDING': 'ACTION_MAIN_MENU_CONFIG_BUILDING',
            'ACTION_MAIN_MENU_CONFIG_BUSINESS_CREDENTIAL': 'ACTION_MAIN_MENU_CONFIG_BUSINESS_CREDENTIAL',
            'ACTION_MAIN_MENU_CONFIG_CONCURRENT_REVIEW_FREQUENCY': 'ACTION_MAIN_MENU_CONFIG_CONCURRENT_REVIEW_FREQUENCY',
            'ACTION_MAIN_MENU_CONFIG_CONTACT_ADDRESS': 'ACTION_MAIN_MENU_CONFIG_CONTACT_ADDRESS',
            'ACTION_MAIN_MENU_CONFIG_EMPLOYEE': 'ACTION_MAIN_MENU_CONFIG_EMPLOYEE',
            'ACTION_MAIN_MENU_CONFIG_GROUP_SESSION_FREQUENCY': 'ACTION_MAIN_MENU_CONFIG_GROUP_SESSION_FREQUENCY',
            'ACTION_MAIN_MENU_CONFIG_INSURANCE_AUDIT_CONFIG': 'ACTION_MAIN_MENU_CONFIG_INSURANCE_AUDIT_CONFIG',
            'ACTION_MAIN_MENU_CONFIG_INSURANCE_TYPE': 'ACTION_MAIN_MENU_CONFIG_INSURANCE_TYPE',
            'ACTION_MAIN_MENU_CONFIG_LABORATORY': 'ACTION_MAIN_MENU_CONFIG_LABORATORY',
            'ACTION_MAIN_MENU_CONFIG_LEVEL_OF_CARE': 'ACTION_MAIN_MENU_CONFIG_LEVEL_OF_CARE',
            'ACTION_MAIN_MENU_CONFIG_MEDICATIONS': 'ACTION_MAIN_MENU_CONFIG_MEDICATIONS',
            'ACTION_MAIN_MENU_CONFIG_PATIENT_MEDICATION_MISSED': 'ACTION_MAIN_MENU_CONFIG_PATIENT_MEDICATION_MISSED',
            'ACTION_MAIN_MENU_CONFIG_PATIENT_PROCESS': 'ACTION_MAIN_MENU_CONFIG_PATIENT_PROCESS',
            'ACTION_MAIN_MENU_CONFIG_RATIONALE': 'ACTION_MAIN_MENU_CONFIG_RATIONALE',
            'ACTION_MAIN_MENU_CONFIG_REACTION': 'ACTION_MAIN_MENU_CONFIG_REACTION',
            'ACTION_MAIN_MENU_CONFIG_REPORT_CATEGORY': 'ACTION_MAIN_MENU_CONFIG_REPORT_CATEGORY',
            'ACTION_MAIN_MENU_CONFIG_RESOURCES': 'ACTION_MAIN_MENU_CONFIG_RESOURCES',
            'ACTION_MAIN_MENU_CONFIG_ROLES': 'ACTION_MAIN_MENU_CONFIG_ROLES',
            'ACTION_MAIN_MENU_CONFIG_ROOM': 'ACTION_MAIN_MENU_CONFIG_ROOM',
            'ACTION_MAIN_MENU_CONFIG_SERVICE_PROVIDER': 'ACTION_MAIN_MENU_CONFIG_SERVICE_PROVIDER',
            'ACTION_MAIN_MENU_CONFIG_SESSION_TYPE': 'ACTION_MAIN_MENU_CONFIG_SESSION_TYPE',
            'ACTION_MAIN_MENU_CONFIG_SEVERITY': 'ACTION_MAIN_MENU_CONFIG_SEVERITY',
            'ACTION_MAIN_MENU_CONFIG_SHIFT_TYPE': 'ACTION_MAIN_MENU_CONFIG_SHIFT_TYPE',
            'ACTION_MAIN_MENU_CONFIG_SOURCE_MEDICATION': 'ACTION_MAIN_MENU_CONFIG_SOURCE_MEDICATION',
            'ACTION_MAIN_MENU_CONFIG_TYPE_OF_CHECK': 'ACTION_MAIN_MENU_CONFIG_TYPE_OF_CHECK',
            'ACTION_MAIN_MENU_CONFIG_TYPE_OF_ETHNICITY': 'ACTION_MAIN_MENU_CONFIG_TYPE_OF_ETHNICITY',
            'ACTION_MAIN_MENU_CONFIG_INCIDENT_TYPE': 'ACTION_MAIN_MENU_CONFIG_INCIDENT_TYPE',
            'ACTION_MAIN_MENU_CONFIG_TYPE_PAYMENT_METHODS': 'ACTION_MAIN_MENU_CONFIG_TYPE_PAYMENT_METHODS',
            'ACTION_MAIN_MENU_CONFIG_TYPE_RACE': 'ACTION_MAIN_MENU_CONFIG_TYPE_RACE',
            'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACT_TYPE': 'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACT_TYPE',
            'ACTION_MAIN_MENU_CONFIG_INSURANCE_LEVEL': 'ACTION_MAIN_MENU_CONFIG_INSURANCE_LEVEL',
            'ACTION_MAIN_MENU_CONFIG_INSURANCE_RELATION_CONFIG': 'ACTION_MAIN_MENU_CONFIG_INSURANCE_RELATION_CONFIG',
            'ACTION_MAIN_MENU_CONFIG_TYPE_DISCHARGE_TYPE': 'ACTION_MAIN_MENU_CONFIG_TYPE_DISCHARGE_TYPE',
            'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_PROPERTY_CONDITION': 'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_PROPERTY_CONDITION',
            'ACTION_MAIN_MENU_CONFIG_TYPE_CONTACT_FACILITY_TYPE': 'ACTION_MAIN_MENU_CONFIG_TYPE_CONTACT_FACILITY_TYPE',
            'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACTS_RELATIONSHIP': 'ACTION_MAIN_MENU_CONFIG_TYPE_PATIENT_CONTACTS_RELATIONSHIP',
            'ACTION_MAIN_MENU_CONFIG_TYPE_SPECIALITY': 'ACTION_MAIN_MENU_CONFIG_TYPE_SPECIALITY',
            'ACTION_MAIN_MENU_CONFIG_VIA': 'ACTION_MAIN_MENU_CONFIG_VIA',
            'ACTION_MAIN_MENU_CONFIG_TYPE_MARITAL_STATUS': 'ACTION_MAIN_MENU_CONFIG_TYPE_MARITAL_STATUS',
            'ACTION_MAIN_MENU_CONFIG_TYPE_FOOD_DIETS':'ACTION_MAIN_MENU_CONFIG_TYPE_FOOD_DIETS',
            'ACTION_MAIN_MENU_CONFIG_GLUCOSE_INTERVENTION':'ACTION_MAIN_MENU_CONFIG_GLUCOSE_INTERVENTION',
            'ACTION_MAIN_MENU_CONFIG_FACILITY_PHONE_NUMBER':'ACTION_MAIN_MENU_CONFIG_FACILITY_PHONE_NUMBER',
            'ACTION_MAIN_MENU_CONFIG_GREETING': 'ACTION_MAIN_MENU_CONFIG_GREETING',
            'ACTION_MAIN_MENU_CONFIG_MESSAGE_HISTORY':'ACTION_MAIN_MENU_CONFIG_MESSAGE_HISTORY',
            'ACTION_MAIN_MENU_CONFIG_VERIFYTX_SCHEDULE': 'ACTION_MAIN_MENU_CONFIG_VERIFYTX_SCHEDULE',
            'ACTION_MAIN_MENU_CONFIG_FAX_NUMBER':'ACTION_MAIN_MENU_CONFIG_FAX_NUMBER',
            'ACTION_MAIN_MENU_CONFIG_ROUTE':'ACTION_MAIN_MENU_CONFIG_ROUTE',
            'ACTION_MAIN_MENU_CONFIG_CONTACTS_FACILITY': 'ACTION_MAIN_MENU_CONFIG_CONTACTS_FACILITY',
            'ACTION_MAIN_MENU_CONFIG_DOSE_FORM': 'ACTION_MAIN_MENU_CONFIG_DOSE_FORM',
            'ACTION_MAIN_MENU_CONFIG_CHART_BLOCKED': 'ACTION_MAIN_MENU_CONFIG_CHART_BLOCKED',
            'ACTION_MAIN_MENU_CONFIG_AUTO_FAX': 'ACTION_MAIN_MENU_CONFIG_AUTO_FAX',
            'ACTION_MAIN_MENU_CONFIG_APPOINTMENT_TYPE': 'ACTION_MAIN_MENU_CONFIG_APPOINTMENT_TYPE',
            'ACTION_MAIN_MENU_CONFIG_BLOCKED_TIME_REASON': 'ACTION_MAIN_MENU_CONFIG_BLOCKED_TIME_REASON',
            'ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN': 'ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN',
            'ACTION_MAIN_MENU_CONFIG_COVID_ICD10': 'ACTION_MAIN_MENU_CONFIG_COVID_ICD10',
            'ACTION_MAIN_MENU_CONFIG_COVID_QUESTION': 'ACTION_MAIN_MENU_CONFIG_COVID_QUESTION',
            'ACTION_MAIN_MENU_CONFIG_COVID_FACILITY': 'ACTION_MAIN_MENU_CONFIG_COVID_FACILITY',
            'ACTION_MAIN_MENU_CONFIG_COVID_FACILITY_LAB': 'ACTION_MAIN_MENU_CONFIG_COVID_FACILITY_LAB',
            'ACTION_MAIN_MENU_CONFIG_COVID_LAB_COMPENDIUM': 'ACTION_MAIN_MENU_CONFIG_COVID_LAB_COMPENDIUM',
            'ACTION_MAIN_MENU_CONFIG_PROTOCOL_GROUP':'ACTION_MAIN_MENU_CONFIG_PROTOCOL_GROUP',
            'ACTION_MAIN_MENU_CONFIG_INCIDENT_DEPARTMENT':'ACTION_MAIN_MENU_CONFIG_INCIDENT_DEPARTMENT',
            'ACTION_MAIN_MENU_CONFIG_INCIDENT_SEVERITY': 'ACTION_MAIN_MENU_CONFIG_INCIDENT_SEVERITY',
            'ACTION_MAIN_MENU_CONFIG_PORT': 'ACTION_MAIN_MENU_CONFIG_PORT',
            'ACTION_MAIN_MENU_CONFIG_VESSEL': 'ACTION_MAIN_MENU_CONFIG_VESSEL',
            'ACTION_MAIN_MENU_CONFIG_EVALUATION_TEMPLATE_MARS_MORS': 'ACTION_MAIN_MENU_CONFIG_EVALUATION_TEMPLATE_MARS_MORS',
            //  jhipster-needle-actions-constants
            //Crm.
            'ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN': 'ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN',
            'ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS': 'ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS',
            'ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT': 'ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT',
            'ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY': 'ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY',
            'ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL': 'ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL',
            'ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL_STATUS': 'ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL_STATUS',
            'ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_SOURCE': 'ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_SOURCE',
            'ACTION_MAIN_MENU_CONFIG_CRM_TAG': 'ACTION_MAIN_MENU_CONFIG_CRM_TAG',
            'ACTION_MAIN_MENU_CONFIG_INST_CLAIM_CONF': 'ACTION_MAIN_MENU_CONFIG_INST_CLAIM_CONF',
            'ACTION_MAIN_MENU_CONFIG_PRO_CLAIM_CONF': 'ACTION_MAIN_MENU_CONFIG_PRO_CLAIM_CONF',

            'ACTION_PATIENT_LIST': 'ACTION_PATIENT_LIST',
            'ACTION_PATIENT_NEW': 'ACTION_PATIENT_NEW',
            'ACTION_PATIENT_CLOSE_CHART': 'ACTION_PATIENT_CLOSE_CHART',
            'ACTION_PATIENT_EDIT_ID': 'ACTION_PATIENT_EDIT_ID',
            'ACTION_PATIENT_EDIT_INFO': 'ACTION_PATIENT_EDIT_INFO',
            'ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS': 'ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS',
            'ACTION_MASTER_EDIT': 'ACTION_MASTER_EDIT',
            'ACTION_MASTER_EDIT_GROUP_SESSION': 'ACTION_MASTER_EDIT_GROUP_SESSION',
            'ACTION_PATIENT_INSURANCE_VIEW': 'ACTION_PATIENT_INSURANCE_VIEW',
            'ACTION_PATIENT_CONTACTS_VIEW': 'ACTION_PATIENT_CONTACTS_VIEW',
            'ACTION_PATIENT_CARE_TEAM_VIEW': 'ACTION_PATIENT_CARE_TEAM_VIEW',
            'ACTION_PATIENT_CARE_COORDINATION_VIEW': 'ACTION_PATIENT_CARE_COORDINATION_VIEW',
            'ACTION_PATIENT_ALLERGIES_VIEW': 'ACTION_PATIENT_ALLERGIES_VIEW',
            'ACTION_PATIENT_DIETS_VIEW': 'ACTION_PATIENT_DIETS_VIEW',
            'ACTION_PATIENT_VITALS_VIEW': 'ACTION_PATIENT_VITALS_VIEW',
            'ACTION_PATIENT_GLUCOSE_VIEW': 'ACTION_PATIENT_GLUCOSE_VIEW',
            'ACTION_PATIENT_WEIGHT_VIEW': 'ACTION_PATIENT_WEIGHT_VIEW',
            'ACTION_PATIENT_ALCOHOL_VIEW': 'ACTION_PATIENT_ALCOHOL_VIEW',
            'ACTION_PATIENT_DRUGS_VIEW': 'ACTION_PATIENT_DRUGS_VIEW',
            'ACTION_PATIENT_FILE_VIEW': 'ACTION_PATIENT_FILE_VIEW',
            'ACTION_PATIENT_CHART_NOTE_VIEW': 'ACTION_PATIENT_CHART_NOTE_VIEW',
            'ACTION_PATIENT_CONCURRENT_REVIEW_VIEW': 'ACTION_PATIENT_CONCURRENT_REVIEW_VIEW',
            'ACTION_PATIENT_VERIFYTX_VIEW': 'ACTION_PATIENT_VERIFYTX_VIEW',
            'ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY': 'ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY',
            'ACTION_PATIENT_CHART_DISCHARGE_HISTORY': 'ACTION_PATIENT_CHART_DISCHARGE_HISTORY',
            'ACTION_PATIENT_CHART_TRACK_HISTORY': 'ACTION_PATIENT_CHART_TRACK_HISTORY',
            'ACTION_PATIENT_DIAGNOSES_VIEW': 'ACTION_PATIENT_DIAGNOSES_VIEW',
            'ACTION_PATIENT_LAB_ORDERS_VIEW': 'ACTION_PATIENT_LAB_ORDERS_VIEW',
            'ACTION_PATIENT_ELABS_VIEW': 'ACTION_PATIENT_ELABS_VIEW',
            'ACTION_PATIENT_GROUP_SESSION_VIEW': 'ACTION_PATIENT_GROUP_SESSION_VIEW',
            'ACTION_PATIENT_EVALUATION_VIEW': 'ACTION_PATIENT_EVALUATION_VIEW',
            'ACTION_PATIENT_MEDICATION_VIEW': 'ACTION_PATIENT_MEDICATION_VIEW',

            'ACTION_ADD_PATIENT_ORDER_LAB_REQUISITION': 'ACTION_ADD_PATIENT_ORDER_LAB_REQUISITION',
            'ACTION_MAIN_MENU_SUPER_BILL': 'ACTION_MAIN_MENU_SUPER_BILL',
            'ACTION_MAIN_MENU_304B_PROGRAM_ELIGIBILITY': 'ACTION_MAIN_MENU_304B_PROGRAM_ELIGIBILITY',
            'ACTION_MAIN_MENU_CONFIG_CORPORATION_PROGRAM_ELIGIBILITY': 'ACTION_MAIN_MENU_CONFIG_CORPORATION_PROGRAM_ELIGIBILITY',
            'ACTION_MAIN_MENU_CONFIG_FACILITY_PROGRAM_ELIGIBILITY': 'ACTION_MAIN_MENU_CONFIG_FACILITY_PROGRAM_ELIGIBILITY',
            'ACTION_MAIN_MENU_CONFIG_MEDICATION_PROGRAM_ELIGIBILITY': 'ACTION_MAIN_MENU_CONFIG_MEDICATION_PROGRAM_ELIGIBILITY',
            'ACTION_MAIN_MENU_CONFIG_BIN': 'ACTION_MAIN_MENU_CONFIG_BIN',
            'ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY': 'ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY',
            'ACTION_MAIN_MENU_CONFIG_LABORATORY_LOGO': 'ACTION_MAIN_MENU_CONFIG_LABORATORY_LOGO',
            'ACTION_MAIN_MENU_CONFIG_FACILITY_ICD10': 'ACTION_MAIN_MENU_CONFIG_FACILITY_ICD10',
            'ACTION_MAIN_MENU_CONFIG_FACILITY_PHARMACY': 'ACTION_MAIN_MENU_CONFIG_FACILITY_PHARMACY',

            'PRO_CLAIM_CONFIG': 'PRO_CLAIM_CONFIG',
            'ACTION_ADD_PATIENT_ORDER_CREATE': 'ACTION_ADD_PATIENT_ORDER_CREATE',
            'ACTION_RC_UPLOAD_CHART': 'ACTION_RC_UPLOAD_CHART',
            'ACTION_DELETE_CARE_DELIVERY': 'ACTION_DELETE_CARE_DELIVERY'
        });
})();
