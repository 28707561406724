(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPrescriptionDetailController', RcopiaPrescriptionDetailController);

    RcopiaPrescriptionDetailController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'Via', 'CoreService',
        'Route', 'rcopiaPrescription', 'PatientMedication', 'signAuthorities',
        'Allergies', 'RcopiaUtils', 'chart'];

    function RcopiaPrescriptionDetailController($timeout, $scope, $uibModalInstance, Via, CoreService,
                                                Route, rcopiaPrescription, PatientMedication, signAuthorities,
                                                Allergies, RcopiaUtils, chart) {
        var vm = this;

        vm.rcopiaPrescription = rcopiaPrescription;
        vm.chart = chart;

        vm.allergiesStr = "";
        vm.rationaleStr = "";

        vm.getDirections = getDirections;
        vm.getQuantity = getQuantity;
        vm.getRefills = getRefills;
        vm.getDaysSupply = getDaysSupply;
        vm.dirToPharmacy = dirToPharmacy;
        vm.doNotFillBefore = doNotFillBefore;

        init();

        function init() {
        }

        vm.clear = clear;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getDirections(rcopiaPrescription) {
            return RcopiaUtils.getDirections(rcopiaPrescription);
        }

        function getQuantity(rcopiaPrescription) {
            return RcopiaUtils.getQuantity(rcopiaPrescription);
        }

        function getRefills(rcopiaPrescription) {
            return RcopiaUtils.getRefills(rcopiaPrescription);
        }

        function getDaysSupply(rcopiaPrescription) {
            return RcopiaUtils.getDaysSupply(rcopiaPrescription);
        }

        function dirToPharmacy(rcopiaPrescription) {
            return RcopiaUtils.dirToPharmacy(rcopiaPrescription);
        }

        function doNotFillBefore(rcopiaPrescription) {
            return RcopiaUtils.doNotFillBefore(rcopiaPrescription);
        }
    }
})();
