(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPopupController', RcopiaPopupController);

    RcopiaPopupController.$inject = ['CoreService', 'RcopiaAccount', '$sce', 'options', '$uibModalInstance'];

    function RcopiaPopupController(CoreService, RcopiaAccount, $sce, options, $uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        init();

        function init() {
            if (options.mode === 'limpMode') {
                RcopiaAccount.rcopiaLimpToken(
                    {
                        corporationId: CoreService.getCorporation().id,
                        startupScreen: options.startupScreen
                    },
                    function (token) {
                        vm.rcopiaToken = $sce.trustAsResourceUrl(token.value);
                    });
            } else {
                RcopiaAccount.rcopiaToken(
                    {
                        corporationId: CoreService.getCorporation().id,
                        startupScreen: options.startupScreen,
                        chartId: options.chartId
                    },
                    function (token) {
                        vm.rcopiaToken = $sce.trustAsResourceUrl(token.value);
                    });
            }
        }

        function clear() {
            $uibModalInstance.close({});
        }
    }
})();
