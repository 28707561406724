(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PharmacyController', PharmacyController);

    PharmacyController.$inject = ['GenericEntityDatatableService', 'Pharmacy', '$uibModal',
        'ConfirmationService', 'toastr', '$q'];

    function PharmacyController(GenericEntityDatatableService, Pharmacy, $uibModal,
                                ConfirmationService, toastr, $q) {
        var vm = this;

        vm.descriptor = {
            title: 'Pharmacy',
            newButtonTitle: 'New Pharmacy',
            entityClassHumanized: 'Pharmacy',
            entityStateName: 'pharmacy',
            service: Pharmacy,
            serviceMethod: 'findAllByFilter',
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'city',
                    title: 'City',
                },
                {
                    name: 'state',
                    title: 'State',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Pharmacy',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/pharmacy/update/pharmacy-dialog.html',
                    controller: 'PharmacyDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: [function () {
                            return {
                                id: null,
                                rcopiaID: null,
                                rcopiaMasterID: null,
                                deleted: null,
                                ncpdpid: null,
                                npi: null,
                                name: null,
                                address1: null,
                                address2: null,
                                crossStreet: null,
                                city: null,
                                state: null,
                                zip: null,
                                phone: null,
                                fax: null,
                                is24Hour: null,
                                level3: null,
                                electronic: null,
                                mailOrder: null,
                                retail: null,
                                longTermCare: null,
                                specialty: null,
                                canReceiveControlledSubstance: null,
                                scriptVersion: null,
                                inHouseDispensing: null,
                                compounding: null,
                                durableMedicalEquipment: null,
                                kiosk: null
                            };
                        }]
                    }
                }).result.then(function () {
                    toastr.success('Pharmacy was saved', 'Success');

                    resolve();
                });
            })
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/pharmacy/update/pharmacy-dialog.html',
                    controller: 'PharmacyDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['Pharmacy', function (Pharmacy) {
                            return Pharmacy.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    toastr.success('Pharmacy was saved', 'Success');

                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Pharmacy').result.then(function () {
                    Pharmacy.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();
