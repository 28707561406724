(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityPharmacyController', FacilityPharmacyController);

    FacilityPharmacyController.$inject = ['FacilityPharmacy', 'CoreService', 'GenericEntityDatatableService', '$uibModal',
        'ConfirmationService', '$q'];

    function FacilityPharmacyController(FacilityPharmacy, CoreService, GenericEntityDatatableService, $uibModal,
                                     ConfirmationService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'facility-pharmacy'

        vm.descriptor = {
            title: 'Facility Pharmacy',
            entityClassHumanized: 'Facility Pharmacy',
            newButtonTitle: 'Add Pharmacy',
            entityStateName: entityStateName,
            service: FacilityPharmacy,
            serviceMethod: 'findAllByFilter',
            params: params,
            newAction: false,
            columns: [
                {
                    title: 'Name',
                    render: function (data) {
                        return data.pharmacy.name;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Pharmacy',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-pharmacy/update/facility-pharmacy-dialog.html',
                controller: 'FacilityPharmacyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            pharmacy: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Facility Pharmacy').result.then(function () {
                    FacilityPharmacy.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();
