(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('rcopia-last-update-date', {
                parent: 'entity',
                url: '/rcopia-last-update-date',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_SYSTEM'],
                    pageTitle: 'RcopiaLastUpdateDates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/rcopia-last-update-date/list/rcopia-last-update-dates.html',
                        controller: 'RcopiaLastUpdateDateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('rcopia-last-update-date-detail', {
                parent: 'entity',
                url: '/rcopia-last-update-date/{id}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_SYSTEM'],
                    pageTitle: 'RcopiaLastUpdateDate'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/rcopia-last-update-date/detail/rcopia-last-update-date-detail.html',
                        controller: 'RcopiaLastUpdateDateDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'RcopiaLastUpdateDate', function ($stateParams, RcopiaLastUpdateDate) {
                        return RcopiaLastUpdateDate.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'rcopia-last-update-date',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
    }
})();
