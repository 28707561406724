(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SeverityController', SeverityController);

    SeverityController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Severity'];

    function SeverityController(GenericEntityDatatableService, CoreService, Severity) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Severity',
            newButtonTitle: 'New Severity',
            entityClassHumanized: 'Severity',
            entityStateName: 'severity',
            service: Severity,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'ctConceptID',
                    title: 'SNOMED-CT Concept ID',
                }
            ]
        }
    }
})();
