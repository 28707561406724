(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaChartController', RcopiaChartController);

    RcopiaChartController.$inject = ['$sce', 'chart', 'RcopiaAccount', 'CoreService', '$rootScope', 'access',
        '$document', '$scope', 'rcopiaAccount', '$state', 'Rcopia', 'toastr', 'TAB'];

    function RcopiaChartController($sce, chart, RcopiaAccount, CoreService, $rootScope, access,
                              $document, $scope, rcopiaAccount, $state, Rcopia, toastr, TAB) {
        var vm = this;

        vm.chart = chart;
        vm.access = access;
        vm.rcopiaAccount = rcopiaAccount;
        vm.corporation = CoreService.getCorporation();
        vm.loading = false;

        vm.transfer = transfer;

        function transfer() {
            vm.loading = true;

            Rcopia.sendPatient({id: chart.id}, function (result) {
                if (result.length === 0) {
                    $state.go('chart', {id: vm.chart.id, tabId: "" + TAB.BASIC_INFORMATION}, {reload: 'resource'});
                } else {
                    toastr.error(result[0].text, {'time-out': 10000});
                }

                vm.loading = false;
            }, function () {
                vm.loading = false;
                toastr.error('Something went wrong', 'Error');
            });
        }
    }
})();
