(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DiagnosesController', DiagnosesController);

    DiagnosesController.$inject = ['chart', 'Diagnoses', 'BusinessCredential', 'CoreService', '$sessionStorage',
        '$uibModal', 'Employee', 'PopupService', 'BUSINESS_ROLES', 'toastr', 'ChartPicture', 'access',
        'ACTIONS', 'Auth', 'ConfirmationService', '$rootScope'];

    function DiagnosesController(chart, Diagnoses, BusinessCredential, CoreService, $sessionStorage,
                                 $uibModal, Employee, PopupService, BUSINESS_ROLES, toastr, ChartPicture, access,
                                 ACTIONS, Auth, ConfirmationService, $rootScope) {
        var vm = this;

        vm.chart = chart;

        vm.activeTab = $sessionStorage.activePatientTabDiagnoses ? $sessionStorage.activePatientTabDiagnoses.activeTab : 0;
        vm.name = chart.lastName + " " + chart.firstName;
        vm.mrNumber = chart.mrNo;

        vm.employee = CoreService.getCurrentEmployee();
        vm.facility = CoreService.getCurrentFacility();

        vm.toggleItem = toggleItem;
        vm.loadDiagnoses = loadDiagnoses;
        vm.add = add;
        vm.del = del;
        vm.edit = edit;
        vm.keepActiveTab = keepActiveTab;
        vm.discontinue = discontinue;
        vm.onDragEnd = onDragEnd;

        init();

        loadData();

        function init() {
            BusinessCredential.getCredentialByRole({
                businessRole: BUSINESS_ROLES.DISCONTINUE_DIAGNOSES,
                id: vm.facility.id
            }, function (result) {
                vm.credentials = result;

                vm.itHasCredentials = _.intersection(vm.employee.credentials,
                    _.map(vm.credentials, function (credential) {
                        return credential.name;
                    })
                ).length > 0;
            });
        }

        function toggleItem() {
            CoreService.togglePanel('diagnoses');
        }

        function keepActiveTab(index) {
            $sessionStorage.activePatientTabDiagnoses = {activeTab: index};
            vm.activeTab = index;
        }

        function loadData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW, access)) {

                return;
            }

            vm.diagnosesActive = [];
            vm.diagnosesInactive = [];

            Diagnoses.findAllActiveDiagnosesByChartId({id: chart.id}, function (result) {
                vm.diagnosesActive = result;
            })


            Diagnoses.findAllInactiveDiagnosesByChart({id: chart.id}, function (result) {
                vm.diagnosesInactive = result;
            });
        }

        function loadDiagnoses() {
            var dd = {
                pageOrientation: 'landscape',
                content: [
                    {
                        text: 'Diagnoses',
                        style: 'header',
                        alignment: 'center'
                    },
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                        fontSize: 11,
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 12,
                        color: 'black'
                    }
                }
            };


            var headerActive = {
                text: 'Active Diagnoses:',
                fontSize: 12,
                bold: true,
                margin: [0, 20, 0, 8]
            };

            var activeDiagnoses = {
                style: 'tableExample',
                table: {
                    widths: [120, 65, 40, 60, 60, 60, 60, 70, '*'],
                    headerRows: 1,
                    body: [
                        [
                            {text: 'Diagnoses', style: 'tableHeader'},
                            {text: 'Rank', style: 'tableHeader'},
                            {text: 'Acuity', style: 'tableHeader'},
                            {text: 'Start date', style: 'tableHeader'},
                            {text: 'End Date', style: 'tableHeader'},
                            {text: 'Comment', style: 'tableHeader'},
                            {text: 'Signed By', style: 'tableHeader'},
                            {text: 'Signed Date', style: 'tableHeader'},
                            {text: 'Signature', style: 'tableHeader'}
                        ]
                    ]
                },
                layout: 'lightHorizontalLines'
            };

            var headerInactive = {
                text: 'Inactive Diagnoses:',
                fontSize: 12, bold: true,
                margin: [0, 20, 0, 8]
            };

            var inactiveDiagnoses = {
                style: 'tableExample',
                table: {
                    widths: [120, 65, 40, 60, 60, 60, 60, 70, '*'],
                    headerRows: 1,
                    body: [
                        [
                            {text: 'Diagnoses', style: 'tableHeader'},
                            {text: 'Rank', style: 'tableHeader'},
                            {text: 'Acuity', style: 'tableHeader'},
                            {text: 'Start date', style: 'tableHeader'},
                            {text: 'End Date', style: 'tableHeader'},
                            {text: 'Comment', style: 'tableHeader'},
                            {text: 'Signed By', style: 'tableHeader'},
                            {text: 'Signed Date', style: 'tableHeader'},
                            {text: 'Signature', style: 'tableHeader'}
                        ]
                    ]
                },
                layout: 'lightHorizontalLines'
            };

            Diagnoses.findAllActiveDiagnosesByChartWithSignature({id: chart.id}, function (result) {
                if (result.length) {
                    result.forEach(function (element) {
                        var starDate = (element.startDate) ? moment(element.startDate).format("MM/DD/YYYY") : {
                            text: '-',
                            alignment: 'center'
                        };
                        var endDate = (element.endDate) ? moment(element.endDate).format("MM/DD/YYYY") : {
                            text: '-',
                            alignment: 'center'
                        };
                        var icd = element.icd10.code + " " + element.icd10.description;

                        var signedBy = "";
                        var image = "";
                        var signedDate = "";

                        if (element.signed) {
                            signedBy = element.signedBy.lastName + ", " + element.signedBy.firstName;
                            image = {image: element.signature.signature, width: 100};
                            signedDate = (element.signedDate) ? moment(element.signedDate).format("MM/DD/YYYY") : {
                                text: '-',
                                alignment: 'center'
                            };
                        }

                        activeDiagnoses.table.body.push([
                            icd,
                            element.rank,
                            element.acuity.name,
                            starDate,
                            endDate,
                            element.comment,
                            signedBy,
                            signedDate,
                            image
                        ]);
                    });
                    dd.content.push(headerActive);
                    dd.content.push(activeDiagnoses);
                }
                Diagnoses.findAllInactiveDiagnosesByChartWithSignature({id: chart.id}, function (result) {
                    if (result.length) {
                        result.forEach(function (element) {
                            var starDate = (element.startDate) ? moment(element.startDate).format("MM/DD/YYYY") : {
                                text: '-',
                                alignment: 'center'
                            };
                            var endDate = (element.endDate) ? moment(element.endDate).format("MM/DD/YYYY") : {
                                text: '-',
                                alignment: 'center'
                            };

                            var signedBy = "";
                            var image = "";
                            var signedDate = "";

                            if (element.signed) {
                                signedBy = element.signedBy.lastName + ", " + element.signedBy.firstName;
                                image = {image: element.signature.signature, width: 100};
                                signedDate = (element.signedDate) ? moment(element.signedDate).format("MM/DD/YYYY") : {
                                    text: '-',
                                    alignment: 'center'
                                };
                            }

                            var icd = element.icd10.code + " " + element.icd10.description;
                            inactiveDiagnoses.table.body.push([
                                icd,
                                element.rank,
                                element.acuity.name,
                                starDate,
                                endDate,
                                element.comment,
                                signedBy,
                                signedDate,
                                image
                            ]);
                        });
                        dd.content.push(headerInactive);
                        dd.content.push(inactiveDiagnoses);
                    }

                    ChartPicture.getCroppedImageByChart({id: chart.id}, function (result) {
                        vm.chartImage = result.image;

                        dd.content.push(getTableHeader(vm.chartImage));

                        vm.pdf = pdfMake.createPdf(dd);
                        vm.pdf.print();
                    }, function () {
                        vm.chartImage = CoreService.patientPictureDefault().picture;

                        dd.content.push(getTableHeader(vm.chartImage));

                        vm.pdf = pdfMake.createPdf(dd);
                        vm.pdf.print();
                    });
                });
            });
        }

        function add() {
            PopupService.showDiagnosesAddPopup(chart.id).result.then(function () {
                loadData();

                $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_problems');
            }, function () {
                loadData();
            });
        }

        function edit(id) {
            PopupService.showDiagnosesEditPopup(id).result.then(function () {
                loadData()

                $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_problems');
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Diagnoses').result.then(function () {
                Diagnoses.delete({id: id}, function () {
                    loadData();

                    $rootScope.$broadcast('bluebookApp:RcopiaReload', 'manage_problems');
                });
            })
        }

        function getTableHeader(chartImage) {
            return {
                style: 'tableExample',
                table: {
                    widths: ['auto', '*', '*'],
                    headerRows: 1,
                    body: [
                        [
                            [{text: 'Name:', style: 'tableHeader'}, {
                                text: 'Mr number:',
                                style: 'tableHeader'
                            }, {text: 'Age:', style: 'tableHeader'}],
                            [{text: vm.name, alignment: 'left'}, {
                                text: vm.mrNumber,
                                alignment: 'left'
                            }, {text: '28', alignment: 'left'}],
                            {
                                image: chartImage,
                                width: 150,
                                height: 150,
                                alignment: 'right'
                            }
                        ],
                    ]
                },
                layout: 'noBorders'
            };
        }

        function discontinue(id) {
            PopupService.showDiagnosesDiscontinuePopup(id).result.then(function (data) {
                Diagnoses.discontinue({
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin,
                    endDate: data.endDate,
                    facilityId: vm.facility.id,
                    id: id
                }, function (result) {
                    loadData();
                    if (result) {
                        toastr.success("The Diagnoses was discontinued successfully.");
                    } else {
                        toastr.error('The Diagnoses could not be discontinued.');
                    }
                }, function () {
                    toastr.error('The Diagnoses could not be discontinued.');
                });
            }, function () {
                // reload();
            });
        }

        function onDragEnd(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].xorder !== i + 1) {
                    list[i].xorder = i + 1;
                    Diagnoses.reorder({id: list[i].id, xorder: list[i].xorder})

                } else {
                    list[i].xorder = i + 1;
                }
            }
        }
    }
})();
