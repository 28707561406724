(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReportExecuteDialogController', ReportExecuteDialogController);

    ReportExecuteDialogController.$inject = ['$uibModalInstance', 'entity', 'Report', 'CoreService', 'DateUtils',
        'GUIUtils', 'Employee', 'ReportExecute', 'toastr'];

    function ReportExecuteDialogController($uibModalInstance, entity, Report, CoreService, DateUtils,
                                           GUIUtils, Employee, ReportExecute, toastr) {
        var vm = this;

        vm.report = entity
        vm.form = {};
        vm.conditions = [];
        vm.reportCategories = [];
        vm.conditionValues = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = {};
        vm.datePickerOpenStatus.date2 = {};
        vm.datePickerOpenStatus.datetime = {};

        vm.initializeValuesByFieldType = initializeValuesByFieldType;
        vm.getValuesByFieldType = getValuesByFieldType;
        vm.openCalendar = openCalendar;
        vm.initDatePicker = initDatePicker;
        vm.getEmployees = getEmployees;
        vm.searchValuesByService = searchValuesByService;
        vm.getCategoryLabel = getCategoryLabel;
        vm.save = save;
        vm.clear = clear;
        vm.getFieldName = getFieldName;
        vm.getFieldId = getFieldId;

        init();

        function init() {
            Report.getReportConditions({reportId: vm.report.id}, function (conditions) {
                if (conditions.length === 0) {
                    var reportExecute = {
                        report: {id: vm.report.id}
                    }

                    execute(reportExecute);
                } else {
                    vm.conditions = conditions;

                    _.forEach(vm.conditions, function (item, index) {
                        initializeValuesByFieldType(item, index);
                    });
                }
            });
        }

        function save() {
            vm.isSaving = true;

            var conditions = _.map(vm.conditions, function (condition) {
                var cnd = {
                    reportField: condition.reportField.id,
                    reportCondition: condition.reportCondition,
                    reportValue: condition.reportValue,
                    reportValue2: condition.reportValue2,
                    reportConditionRef: {id: condition.id}
                }

                switch (condition.reportField.type) {
                    case 'VALUE':
                    case 'ENUM':
                    case 'VALUE_SEARCH': {
                        cnd.reportValue = condition.reportValueObject.id;

                        break;
                    }
                    case 'DATE': {
                        cnd.reportValue = condition.reportValue ? moment(condition.reportValue).format("YYYY-MM-DD") : null;
                        cnd.reportValue2 = condition.reportValue2 ? moment(condition.reportValue2).format("YYYY-MM-DD") : null;
                    }
                }

                return cnd;
            });

            var reportExecute = {
                report: {id: vm.report.id},
                reportExecute: conditions
            }

            execute(reportExecute);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;

            toastr.success('Your report "' + vm.report.name + '" is been generated please go to storage to download it');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getValuesByFieldType(item, index) {
            // item.reportValue = ''
            // item.reportCondition = null;

            initializeValuesByFieldType(item, index);
        }

        function initializeValuesByFieldType(item, index) {

            switch (item.reportField.type) {
                case 'VALUE': {
                    if (!vm.conditionValues[item.reportField.id]) {
                        Report.getValuesByService({
                            facilityId: CoreService.getCurrentFacility().id,
                            value: item.reportField.id
                        }, function (result) {
                            vm.conditionValues[item.reportField.id] = result;
                            var index = _.findIndex(vm.conditionValues[item.reportField.id], function (conditionValue) {
                                return item.reportValue === conditionValue.id.toString();
                            })

                            item.reportValueObject = vm.conditionValues[item.reportField.id][index];
                        })
                    }
                    break;
                }
                case 'ENUM': {
                    if (!vm.conditionValues[item.reportField.id]) {
                        Report.getValuesByEnum({
                            value: item.reportField.id
                        }, function (result) {
                            vm.conditionValues[item.reportField.id] = _.map(result, function (item) {
                                return {'id': item, 'value': item};
                            });
                            var index = _.findIndex(vm.conditionValues[item.reportField.id], function (conditionValue) {
                                return item.reportValue === conditionValue.id.toString();
                            })

                            item.reportValueObject = vm.conditionValues[item.reportField.id][index];
                        });
                    }
                    break;
                }
                case 'DATE': {
                    item.reportValue = item.reportValue ? moment(item.reportValue).toDate() : null;
                    item.reportValue2 = item.reportValue2 ? moment(item.reportValue2).toDate() : null;

                    initDatePicker(item.reportField.type.toLocaleLowerCase(), index);
                    break;
                }
                case 'DATETIME': {
                    item.reportValue = moment(item.reportValue).toDate();
                    item.reportValue2 = item.reportValue2 ? moment(item.reportValue2).toDate() : null;

                    initDatePicker(item.reportField.type.toLocaleLowerCase(), index);
                    break;
                }
                case 'VALUE_SEARCH': {
                    if (vm.report.id === null) {
                        vm.conditionValues[item.reportField.id] = [];
                        vm.conditionValues[item.reportField.id][index] = [];
                    } else if (!vm.conditionValues[item.reportField.id]) {
                        Report.searchValuesByService(
                            {
                                facilityId: CoreService.getCurrentFacility().id,
                                value: item.reportField.id,
                                query: null,
                                id: Number(item.reportValue)
                            }, function (result) {
                                if (!vm.conditionValues[item.reportField.id]) {
                                    vm.conditionValues[item.reportField.id] = [];
                                }

                                vm.conditionValues[item.reportField.id][index] = result;
                                item.reportValueObject = vm.conditionValues[item.reportField.id][index][0];
                            }
                        )
                    }
                    break;
                }
            }
        }

        function openCalendar(date, id) {
            vm.datePickerOpenStatus[date][id] = true;
        }

        function initDatePicker(date, index) {
            vm.datePickerOpenStatus[date][index] = false;
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function searchValuesByService(item, index, query) {
            if (query.length > 3) {

                Report.searchValuesByService(
                    {
                        facilityId: CoreService.getCurrentFacility().id,
                        value: item.reportField.id,
                        query: query,
                        id: null
                    }, function (result) {
                        vm.conditionValues[item.reportField.id][index] = result;
                    }
                )
            }
        }

        function getCategoryLabel(category) {
            return category.charAt(0) + category.replaceAll('_', ' ').slice(1).toLocaleLowerCase();
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getFieldName(index) {
            return 'reportValue' + index;
        }

        function getFieldId(index) {
            return 'field_reportValue' + index;
        }

        function execute(reportExecute) {
            ReportExecute.save(reportExecute, onSaveSuccess, onSaveError);
        }
    }
})();
