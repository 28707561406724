(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaPrescriptionHistoryController', RcopiaPrescriptionHistoryController);

    RcopiaPrescriptionHistoryController.$inject = ['RcopiaPrescription', 'DTOptionsBuilder', 'DTColumnBuilder',
        'DataTablesService', 'chart', '$uibModalInstance', '$timeout', 'ParseLinks',
        'PopupService', '$uibModalStack', 'RcopiaUtils'];

    function RcopiaPrescriptionHistoryController(RcopiaPrescription, DTOptionsBuilder, DTColumnBuilder,
                                                 DataTablesService, chart, $uibModalInstance, $timeout, ParseLinks,
                                                 PopupService, $uibModalStack, RcopiaUtils) {
        var vm = this;
        vm.chart = chart;

        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.patientMedications = [];

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.getDirections = getDirections;
        vm.getQuantity = getQuantity;
        vm.getRefills = getRefills;
        vm.getDaysSupply = getDaysSupply;
        vm.dirToPharmacy = dirToPharmacy;
        vm.doNotFillBefore = doNotFillBefore;

        loadAll();

        function loadAll() {
            RcopiaPrescription.findAllByFilter({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                pending: false
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientMedications = data;
            }

            function onError(error) {
                AlertService.error(error.data);
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function getDirections(rcopiaPrescription) {
            return RcopiaUtils.getDirections(rcopiaPrescription, true);
        }

        function getQuantity(rcopiaPrescription) {
            return RcopiaUtils.getQuantity(rcopiaPrescription, true);
        }

        function getRefills(rcopiaPrescription) {
            return RcopiaUtils.getRefills(rcopiaPrescription, true);
        }

        function getDaysSupply(rcopiaPrescription) {
            return RcopiaUtils.getDaysSupply(rcopiaPrescription);
        }

        function dirToPharmacy(rcopiaPrescription) {
            return RcopiaUtils.dirToPharmacy(rcopiaPrescription);
        }

        function doNotFillBefore(rcopiaPrescription) {
            return RcopiaUtils.doNotFillBefore(rcopiaPrescription);
        }
    }
})();
