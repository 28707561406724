(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDiagnosisDialogController', EvaluationDiagnosisDialogController);

    EvaluationDiagnosisDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity',
        'EvaluationDiagnosis', 'Evaluation', 'Diagnoses', 'Icd10Search', 'evaluation'];

    function EvaluationDiagnosisDialogController($timeout, $scope, $uibModalInstance, entity,
                                                 EvaluationDiagnosis, Evaluation, Diagnoses, Icd10Search, evaluation) {
        var vm = this;

        vm.save = save;
        vm.clear = clear;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.getIcd10 = getIcd10;

        init();

        function init() {
            vm.evaluationDiagnosis = {
                evaluation: evaluation
            }

            vm.diagnoses = entity;
            vm.icd10 = [];

            vm.datePickerOpenStatus = {
                startDate: false,
                endDate: false
            };
            vm.endDateOptions = {};
            vm.startDateOptions = {};
        }

        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diagnoses.endDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diagnoses.startDate;
            vm.endDateOptions.maxDate = new Date();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.close('dismiss');
        }

        function save() {
            vm.isSaving = true;

            vm.evaluationDiagnosis.diagnosisDTO = vm.diagnoses;

            EvaluationDiagnosis.save(vm.evaluationDiagnosis, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
