(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('RcopiaDosageSearch', RcopiaDosageSearch);

    RcopiaDosageSearch.$inject = ['$resource'];

    function RcopiaDosageSearch($resource) {
        var resourceUrl =  'api/_search/rcopia-dosages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
