(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaDosageDetailController', RcopiaDosageDetailController);

    RcopiaDosageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RcopiaDosage', 'RcopiaInstruction'];

    function RcopiaDosageDetailController($scope, $rootScope, $stateParams, previousState, entity, RcopiaDosage, RcopiaInstruction) {
        var vm = this;

        vm.rcopiaDosage = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:rcopiaDosageUpdate', function(event, result) {
            vm.rcopiaDosage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
