(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaMedicationController', RcopiaMedicationController);

    RcopiaMedicationController.$inject = ['$scope', '$state', 'RcopiaMedication', 'Auth', 'ACTIONS', 'access', 'TAB',
        'params', 'chart', 'ParseLinks', '$rootScope', 'DataTablesService', 'AlertService',
        'RcopiaUtils', '$uibModalStack', '$uibModal', 'RcopiaApi', 'PendingPdfChartReport',
        'REPORT_TYPE', 'toastr'];

    function RcopiaMedicationController($scope, $state, RcopiaMedication, Auth, ACTIONS, access, TAB,
                                        params, chart, ParseLinks, $rootScope, DataTablesService, AlertService,
                                        RcopiaUtils, $uibModalStack, $uibModal, RcopiaApi, PendingPdfChartReport,
                                        REPORT_TYPE, toastr) {
        var vm = this;

        vm.chart = chart;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.patientMedications = [];
        vm.rcopiaMedications = [];
        vm.selected = {};
        vm.selectAll = false;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.RCOPIA_MEDICATION);
        vm.access = access;
        vm.lastTab = null;
        vm.filter = {
            status: 'active'
        }

        vm.transition = transition;
        vm.search = search;
        vm.getDirections = getDirections;
        vm.getQuantity = getQuantity;
        vm.getRefills = getRefills;
        vm.history = history;
        vm.loadAll = loadAll;
        vm.launchRcopia = launchRcopia;
        vm.view = view;
        vm.print = print;
        vm.createTakes = createTakes;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_MEDICATION_VIEW, access) || !vm.active) {
                return;
            }

            RcopiaMedication.findAllByFilter({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                active: vm.filter.status === 'active'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientMedications = data;

                // initSelected();
            }

            function onError(error) {
                AlertService.error(error.data);
            }
        }

        function reload() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function search() {
            vm.page = 1;
            transition();
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result === TAB.RCOPIA_MEDICATION) {
                vm.active = true;
                loadAll();
            } else if (vm.lastTab != null && result === TAB.RCOPIA_MEDICATION && vm.lastTab !== TAB.RCOPIA_MEDICATION) {
                loadAll();
            }

            vm.lastTab = result;
        });

        $scope.$on('$destroy', unsubscribe);

        unsubscribe = $rootScope.$on('bluebookApp:RcopiaUpdatePatient', function (event, result) {
            reload();
        });

        $scope.$on('$destroy', unsubscribe);

        function getDirections(rcopiaPrescription) {
            return RcopiaUtils.getDirections(rcopiaPrescription, true);
        }

        function getQuantity(rcopiaPrescription) {
            return RcopiaUtils.getQuantity(rcopiaPrescription, true);
        }

        function getRefills(rcopiaPrescription) {
            return RcopiaUtils.getRefills(rcopiaPrescription, true);
        }

        function history() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/rcopia-medication/history/rcopia-medication-history.html',
                controller: 'RcopiaMedicationHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom-table-fix',
                animation: false,
                size: 'xl',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function launchRcopia() {
            RcopiaUtils.launchRcopiaPopup(
                'manage_medications',
                'patientMode',
                vm.chart.id
            ).result.then(function () {
                RcopiaApi.executeByChart({
                    api: 'updateMedication',
                    chartId: vm.chart.id
                }, function (result) {
                    $rootScope.$broadcast("bluebookApp:RcopiaUpdatePatient");
                })
            })
        }

        function view(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-medication/detail/rcopia-medication-detail.html',
                controller: 'RcopiaMedicationDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    rcopiaMedication: ['RcopiaMedication', function (RcopiaMedication) {
                        return RcopiaMedication.get({id: id}).$promise;
                    }],
                    signAuthorities: ['BusinessCredential', 'BUSINESS_ROLES', 'CoreService', function (BusinessCredential, BUSINESS_ROLES, CoreService) {
                        return BusinessCredential.getCredentialByRole({
                            businessRole: BUSINESS_ROLES.PHYSICIAN, id: CoreService.getCurrentFacility().id
                        }).$promise;
                    }],
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: vm.chart.id}).$promise;
                    }],
                }
            });
        }

        function print(id) {
            PendingPdfChartReport.save({
                chart: {id: vm.chart.id},
                objectId: id,
                name: "" + id,
                reportType: REPORT_TYPE.RCOPIA_MEDICATION
            }, onPrintSuccess, onPrintError);
        }

        function onPrintSuccess(result) {
            toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
        }

        function onPrintError() {
            toastr.error("We couldn't generate the report.");
        }

        function createTakes(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-medication/create-takes/rcopia-medication-create-takes-dialog.html',
                controller: 'RcopiaMedicationDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    rcopiaMedication: ['RcopiaMedication', function (RcopiaMedication) {
                        return RcopiaMedication.get({id: id}).$promise;
                    }],
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: vm.chart.id}).$promise;
                    }],
                }
            });
        }
    }
})();
