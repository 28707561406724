(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaAllergyGroup', RcopiaAllergyGroup);

    RcopiaAllergyGroup.$inject = ['$resource'];

    function RcopiaAllergyGroup ($resource) {
        var resourceUrl =  'api/rcopia-allergy-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, url: 'api/rcopia-allergy-groups'},
            'filter': { method: 'GET', isArray: true, url: 'api/rcopia-allergy-groups/filter/:name'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'sync': { method: 'GET', url: 'api/rcopia-allergy-groups/sync' }
        });
    }
})();
