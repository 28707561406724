(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaAllergyGroupController', RcopiaAllergyGroupController);

    RcopiaAllergyGroupController.$inject = ['$scope', '$state', 'RcopiaAllergyGroup', 'GenericEntityDatatableService'];

    function RcopiaAllergyGroupController($scope, $state, RcopiaAllergyGroup, GenericEntityDatatableService) {
        var vm = this;

        vm.descriptor = {
            title: 'Rcopia Allergy Groups',
            entityClassHumanized: 'Rcopia Accounts',
            entityStateName: 'rcopia-account',
            service: RcopiaAllergyGroup,
            serviceMethod: 'query',
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'id',
                    title: 'id'
                },
                {
                    name: 'rcopiaId',
                    title: 'Rcopia Id',
                },
                {
                    name: 'externalId',
                    title: 'External Id',
                },
                {
                    name: 'firstDataBankGroupId',
                    title: 'First Data Bank Group Id',
                },
                {
                    name: 'isSpecific',
                    title: 'Is Specific',
                },
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'fdbSpecificGroupId',
                    title: 'Fdb Specific Group Id',
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: 'Sync',
                            action: function () {
                                return sync();
                            }
                        })
                    ]
                }
            ],
        }

        function sync() {
            RcopiaAllergyGroup.sync();
        }
    }
})();
