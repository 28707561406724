(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityDialogController', FacilityDialogController);

    FacilityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Facility',
        'ContactsFacility', 'Corporation', 'CoreService', '$rootScope', 'CountryState',
        '$sessionStorage', 'Pharmacy', 'FacilityPharmacy'];

    function FacilityDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Facility,
                                      ContactsFacility, Corporation, CoreService, $rootScope, CountryState,
                                      $sessionStorage, Pharmacy, FacilityPharmacy) {
        var vm = this;
        vm.form = {};
        vm.facility = entity;

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.states = CountryState.query();
        vm.corporations = Corporation.query();
        Facility.getAvailableZoneIds(function (result) {
            vm.timeZones = result;
            vm.zoneId = _.filter(vm.timeZones, {zoneId: vm.facility.timeZone})[0];
        });
        vm.infoTab = $sessionStorage.facilityTab ? $sessionStorage.facilityTab.infoTab : 0;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.annualReview = false;
        vm.accountExist = false;
        Facility.getAllPlaceOfServices(function (result) {
            vm.placeOfService = result;
        });
        vm.pharmacy = vm.facility.id == null ? getPharmacies() : [vm.facility.defaultPharmacy];

        vm.keepTab = keepTab;
        vm.clear = clear;
        vm.save = save;
        vm.getPharmacies = getPharmacies;

        function getPharmacies(query) {
            if (query && query.length > 2) {
                Pharmacy.findAllByFilter({searchQuery: query}, function (result) {
                    vm.pharmacy = [];
                    vm.pharmacy = result;
                });
            } else {
                FacilityPharmacy.findAllByFilter({facilityId: vm.facility.id}, function (result) {
                    vm.pharmacy = [];
                    result.forEach((fP => {
                        vm.pharmacy.push(fP.pharmacy)
                    }))
                });
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.facility) {
                vm.facility.timeZone = vm.zoneId.zoneId;
            }

            Facility.update(vm.facility, onSaveSuccess, onSaveError);
        }

        function keepTab(index) {
            $sessionStorage.facilityTab = {infoTab: index};
            vm.infoTab = index;
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:facilityUpdate', result);
            $rootScope.$broadcast('bluebookApp:updateFacilities', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
