(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaMedication', RcopiaMedication);

    RcopiaMedication.$inject = ['$resource'];

    function RcopiaMedication ($resource) {
        var resourceUrl =  'api/rcopia-medications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFilter': {
                url: 'api/rcopia-medications/filter', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        // _.forEach(data, function (pm) {
                        //     if (pm.startDate) {
                        //         pm.startDate = new Date(DateUtils.convertDateTimeFromServer(pm.startDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        //
                        //     if (pm.endDate) {
                        //         pm.endDate = new Date(DateUtils.convertDateTimeFromServer(pm.endDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        //
                        //     if (pm.createdDate) {
                        //         pm.createdDate = new Date(DateUtils.convertDateTimeFromServer(pm.createdDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        //
                        //     if (pm.discontinueDate) {
                        //         pm.discontinueDate = new Date(DateUtils.convertDateTimeFromServer(pm.discontinueDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        //
                        //     if (pm.signedDate) {
                        //         pm.signedDate = new Date(DateUtils.convertDateTimeFromServer(pm.signedDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        //
                        //     if (pm.faxedToCreatedDate) {
                        //         pm.faxedToCreatedDate = new Date(DateUtils.convertDateTimeFromServer(pm.faxedToCreatedDate)
                        //             .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        //     }
                        // });
                    }

                    return data;
                }
            },
        });
    }
})();
