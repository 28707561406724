(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('rcopia-practice-info', {
                parent: 'entity',
                url: '/rcopia-practice-info',
                ncyBreadcrumb: {
                    label: 'RcopiaPracticeInfo',
                    parent: 'entity'
                },
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM],
                    pageTitle: 'RcopiaPracticeInfos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/rcopia-practice-info/list/rcopia-practice-info.html',
                        controller: 'RcopiaPracticeInfoController',
                        controllerAs: 'vm'
                    }
                },
            });
    }
})();
