(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartDialogController', ChartDialogController);

    ChartDialogController.$inject = ['$uibModalInstance', 'chart', 'Chart', 'CoreService', 'CountryState', 'TypeLevelCare', 'Bed',
        'TypePaymentMethods', 'TypeMaritalStatus', 'TypeRace', 'TypeEthnicity', 'GUIUtils',
        'ContactsFacility', 'TypeDischargeType', '$state', 'DataUtils', 'fancyboxService', 'Employee',
        'Track', 'Vessel', 'Port', 'Principal', 'ROLES', 'Pharmacy', 'FacilityPharmacy'];

    function ChartDialogController($uibModalInstance, chart, Chart, CoreService, CountryState, TypeLevelCare, Bed,
                                   TypePaymentMethods, TypeMaritalStatus, TypeRace, TypeEthnicity, GUIUtils,
                                   ContactsFacility, TypeDischargeType, $state, DataUtils, fancyboxService, Employee,
                                   Track, Vessel, Port, Principal, ROLES, Pharmacy, FacilityPharmacy) {
        var vm = this;

        vm.clear = clear;
        vm.clearReadmitReason = clearReadmitReason;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.getContactsFacility = getContactsFacility;
        vm.setValidationClass = setValidationClass;
        vm.imageDetail = imageDetail;
        vm.changePictureStatus = changePictureStatus;
        vm.attachFile = attachFile;
        vm.filterTracks = filterTracks;
        vm.checkField = checkField;
        vm.getMarketers = getMarketers;
        vm.getAdmissionReps = getAdmissionReps;
        vm.getPharmacies = getPharmacies;

        angular.copy(chart, vm.chart = {});
        vm.facility = CoreService.getCurrentFacility();
        vm.states = CountryState.query();
        vm.gender = GUIUtils.getGender();
        vm.maritalStatus = TypeMaritalStatus.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.race = TypeRace.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.typeDischargeType = TypeDischargeType.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.ethnicity = TypeEthnicity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.paymentMethod = TypePaymentMethods.byFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.typeLevelCare = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.vessels = Vessel.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.ports = Port.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.allowEditBasicInfo = false;
        vm.pharmacies = getPharmacies();

        Chart.countAllByChart({id: chart.id}, function (result) {
            vm.allowEditBasicInfo = result.value <= 1 || Principal.hasAnyAuthority([ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN]);
        });

        if (vm.chart.bed != null) {
            vm.beds = Bed.findAllActiveIncludeChartBed({gender: null, id: vm.facility.id, idBed: vm.chart.bed.id});
        } else {
            vm.beds = Bed.findAllActive({gender: null, id: vm.facility.id});
        }

        vm.form = {};

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateBirth = false;
        vm.datePickerOpenStatus.sobrietyDate = false;
        vm.datePickerOpenStatus.admissionDate = false;
        vm.datePickerOpenStatus.dischargeDate = false;
        vm.datePickerOpenStatus.insuranceDob = false;
        vm.datePickerOpenStatus.dateFirstContact = false;
        vm.datePickerOpenStatus.insuranceDob = [false, false];

        vm.dischargeDateOptions = {
            // minDate: vm.chart.admissionDate ? vm.chart.admissionDate: null
        };

        vm.getMarketers();
        vm.getAdmissionReps();

        function getPharmacies(query) {
            if (query && query.length > 2) {
                Pharmacy.findAllByFilter({searchQuery: query}, function (result) {
                    vm.pharmacies = [];
                    vm.pharmacies = result;
                });
            } else {
                FacilityPharmacy.findAllByFilter({facilityId: CoreService.getCurrentFacility().id}, function (result) {
                    vm.pharmacies = [];
                    result.forEach((fP => {
                        vm.pharmacies.push(fP.pharmacy)
                    }))
                });
            }
        }

        function getContactsFacility(query) {
            var filter = {page: 0, size: 10, searchQuery: query, facilityId: vm.facility.id};

            return ContactsFacility.filter(filter).$promise;
        }

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function clearReadmitReason() {
            vm.chart.readmitReason = null;
        }

        function save() {
            vm.isSaving = true;
            if (vm.chart.id !== null) {
                Chart.update(vm.chart, onSaveSuccess, onSaveError);
            } else {
                Chart.save(vm.chart, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function imageDetail(picture, name) {
            fancyboxService.fancyboxPlus()({
                'href': 'data:' + picture.pictureContentType + ';base64,' + picture.picture,
                'title': name,
                'transitionIn': 'elastic',
                'transitionOut': 'elastic'
            });
        }

        function changePictureStatus(chart) {
            chart.patientLicense.delStatus = true;
        }

        function attachFile(file, chart) {
            var fileEncode = {};
            if (file) {
                DataUtils.toBase64(file, function (base64Data) {
                    fileEncode.picture = base64Data;
                    fileEncode.pictureContentType = file.type;
                    if (chart.patientLicense && chart.patientLicense.id) {
                        chart.patientLicense = angular.extend({}, chart.patientLicense, fileEncode);
                    } else {
                        chart.patientLicense = fileEncode;
                    }
                });
            }
        }

        function filterTracks(searchQuery) {
            if (searchQuery != null) {
                var filter = {
                    page: 0,
                    size: 1000,
                    facilityId: vm.facility.id,
                    searchQuery: vm.searchQuery,
                    enabled: true
                };

                Track.filter(filter, function (result) {
                    vm.tracks = result;
                });
            }
        }

        function checkField(field) {
            return CoreService.enableByFacilityType(vm.chart.facility, field);
        }

        function getMarketers(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.marketer = result;
            });
        }

        function getAdmissionReps(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.admissionReps = result;
            });
        }
    }
})();
