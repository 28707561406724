(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityPharmacyDialogController', FacilityPharmacyDialogController);

    FacilityPharmacyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FacilityPharmacy', 'Pharmacy'];

    function FacilityPharmacyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FacilityPharmacy, Pharmacy) {
        var vm = this;

        vm.facilityPharmacy = entity;
        vm.pharmacy = vm.facilityPharmacy.id == null ? getPharmacy() : [vm.facilityPharmacy.pharmacy];

        vm.clear = clear;
        vm.save = save;
        vm.getPharmacy = getPharmacy;


        function getPharmacy(query) {
            if (query && query.length > 2) {
                Pharmacy.findAllByFilter({searchQuery: query}, function (result) {
                    vm.pharmacy = result;
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.facilityPharmacy.id !== null) {
                FacilityPharmacy.update(vm.facilityPharmacy, onSaveSuccess, onSaveError);
            } else {
                FacilityPharmacy.save(vm.facilityPharmacy, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:facilityPharmacyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
