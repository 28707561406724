/**
 * Created by hermeslm on 10/2/16.
 */

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService', '$uibModalStack', 'LoginService', 'employee',
        'APP', 'Employee', 'CoreService', 'Idle', '$uibModal', 'IDLE', '$rootScope', 'Notifications',
        'ParseLinks', 'AlertService', 'toastr', 'Chart', 'CrmOpportunity', 'showNewChart',
        '$window', 'Rcopia', '$interval', 'RcopiaUtils'];

    function HeaderController($scope, $state, Auth, Principal, ProfileService, $uibModalStack, LoginService, employee,
                              APP, Employee, CoreService, Idle, $uibModal, IDLE, $rootScope, Notifications,
                              ParseLinks, AlertService, toastr, Chart, CrmOpportunity, showNewChart,
                              $window, Rcopia, $interval, RcopiaUtils) {

        var vm = this;

        vm.itemsPerPage = 20;

        vm.appName = APP.APP_NAME;

        vm.appSuffixName = APP.APP_SUFFIX_NAME;
        vm.appShortName = APP.APP_SHORT_NAME;
        vm.toggleBtn = '[data-toggle="offcanvas"]';
        vm.checkFacility = null;
        vm.account = null;
        vm.employeeAccount = null;
        vm.countNoViewedMessages = 0;
        vm.employeeId = CoreService.getCurrentEmployee().userId;

        vm.isNavbarCollapsed = true;
        vm.searchKeyword = '';
        vm.option = 'charts';
        $rootScope.showNewChart = showNewChart.value;
        vm.showNewChart = $rootScope.showNewChart;
        vm.isSmallDevice = $window.outerWidth < 1150;

        vm.employee = employee;
        vm.rcopiaNotifications = [];
        vm.rcopiaTotalNotifications = 0;
        vm.corporation = CoreService.getCorporation();
        vm.interval = $interval(getNotificationCount, 60000);

        //Functions
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.employeeFullName = employeeFullName;
        vm.roleTypeEmployee = roleTypeEmployee;
        vm.openChart = openChart;
        vm.changePassModal = changePassModal;
        vm.replaceSignatureModal = replaceSignatureModal;
        vm.showFeaturePreview = showFeaturePreview;
        vm.getNotificationCount = getNotificationCount;
        vm.rcopiaPopup = rcopiaPopup;
        vm.getNotificationLabel = getNotificationLabel;
        vm.findValue = findValue;

        function changePassModal() {
            $uibModal.open({
                templateUrl: 'app/account/password/password-dialog.html',
                controller: 'PasswordChangeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {
                // $state.reload();
            }, function (result) {
            });
        }

        function replaceSignatureModal() {
            $uibModal.open({
                templateUrl: 'app/account/signature/signature-dialog.html',
                controller: 'SignatureChangeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {
            }, function (result) {
            });
        }

        vm.globalfilter = globalFilter;

        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.facilities = [];
        vm.currentFacility = null;

        vm.$state = $state;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        getNotificationCount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function employeeFullName() {
            if (vm.account)
                return vm.account.firstName + ' ' + vm.account.lastName;
            else
                return 'System';
        }

        function roleTypeEmployee() {
            if (vm.employee) {
                return vm.employee.typeEmployee ? vm.employee.typeEmployee.name : 'Super Admin';
            }
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            CoreService.clearSession();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        // function getEmployee() {
        //     Employee.employeeAccount().$promise.then(function (employee) {
        //             vm.employee = employee;
        //             // vm.employee.avatar = CoreService.getAvatar(employee.gender);
        //             CoreService.setCurrentEmployee(vm.employee);
        //             // $rootScope.$broadcast('employeeGotten');
        //         }, function (reason) {
        //             console.log('Failed getting employee: ' + reason.statusText);
        //         }
        //     );
        // }

        vm.countdown = IDLE.timeout;

        closeModals();

        function closeModals() {
            if (vm.warning) {
                vm.warning.close();
                vm.warning = null;
            }
        }

        var openedModal;

        $rootScope.$on('IdleStart', function () {
            closeModals();

            openedModal = $uibModalStack.getTop();

            vm.warning = $uibModal.open({
                templateUrl: 'warning-dialog.html',
                windowTopClass: 'modal-danger'
            });
        });

        $rootScope.$on('IdleEnd', function () {
            closeModals();

            vm.countdown = IDLE.timeout;
        });

        $rootScope.$on('IdleTimeout', function () {
            closeModals();

            if (openedModal) {
                $uibModalStack.dismiss(openedModal.key);
            }

            logout();
        });

        Idle.watch();


        //////////////NOTIFICATIONS/////////////


        vm.notifications = [];
        vm.notificationsCount = 0;
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.saveViewed = saveViewed;
        vm.notificationsNewCount = 0;
        vm.notificationsByCreatedDateCount = 0;

        //loadAllByCreatedDate();


        if (CoreService.getCurrentEmployee().id !== null) {
            loadAllByViewed();

            loadAll();
        }

        function loadAllByCreatedDate() {

            var fromDate = new Date();

            Notifications.findAllByCreatedDate({
                createdDate: fromDate,
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.notificationsByCreatedDateCount = data.length;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAllByViewed() {
            Notifications.findAllByViewed({
                viewed: false,
                employeeId: CoreService.getCurrentEmployee().id
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.notificationsNewCount = data.length;
                refreshScope();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAll() {
            if (CoreService.getCurrentEmployee().id !== null) {
                Notifications.findAll({
                    employeeId: CoreService.getCurrentEmployee().id,
                    page: vm.page,
                    size: 15
                }, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.notifications.push(data[i]);
                    refreshScope();
                }
                vm.notificationsCount = vm.notifications.length;
                refreshScope();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.notifications = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear() {
            vm.notifications = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function saveViewed(notification) {
            vm.isSaving = true;

            $state.go(notification.state, {}, {reload: false});
            if ((notification.id !== null) && (notification.viewed == false)) {
                notification.viewed = true;
                notification.employee = CoreService.getCurrentEmployee();
                Notifications.update(notification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            //$scope.$emit('bluebookApp:notificationsUpdate', result);
            vm.isSaving = false;
            vm.notifications = [];
            vm.page = 0;
            loadAllByViewed();
            loadAll();
            //$state.reload();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.notifications = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        //Refresh scope UI
        function refreshScope() {
            //if (!$scope.$$phase) {
            $scope.$applyAsync();
            //}
        }

        function globalFilter(searchQuery) {
            if (searchQuery && searchQuery != '') {

                var filter = {};
                filter.searchQuery = searchQuery;
                filter.facilityId = null;
                filter.corporationId = null;
                filter.page = 0;
                filter.size = vm.itemsPerPage;

                vm.charts = [];
                vm.opportunities = [];

                switch (vm.option) {
                    case 'all' :
                        filterCharts(filter);
                        filterCrmOpportunities(filter);

                        break;
                    case 'charts' :
                        filterCharts(filter);

                        break;
                    case 'opportunities' :
                        filterCrmOpportunities(filter);

                        break;
                }
            }
        }

        function filterCharts(filter) {
            vm.facility = CoreService.getCurrentFacility();

            if (vm.facility) {
                filter.facilityId = vm.facility.id;
                Chart.fullFilter(filter, function (result) {
                    vm.charts = result;
                });
            }
        }

        function filterCrmOpportunities(filter) {
            vm.corporation = CoreService.getCorporation();

            if (vm.corporation) {
                filter.corporationId = vm.corporation.id;
                CrmOpportunity.findAllByFilter(filter, function (result) {
                    vm.opportunities = result;
                });
            }
        }

        function openChart(id) {
            vm.searchQuery = null;
            vm.charts = [];
            $state.go('chart', {id: id});
        }

        function showFeaturePreview() {
            $uibModal.open({
                templateUrl: 'app/entities/employee-new-chart/new-chart-preview-dialog.html',
                controller: 'NewChartPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            });
        }

        $rootScope.$watch('showNewChart', function (newVal) {
            if (newVal) {
                vm.showNewChart = newVal;
            }
        })

        var unsubscribe = $rootScope.$on('bluebookApp:selectCorporation', function (event, result) {
            vm.getNotificationCount();
        });

        $scope.$on('$destroy', unsubscribe);
        $scope.$on('$destroy', function () {
            $interval.cancel(vm.interval);
        });

        function getNotificationCount() {
            vm.corporation = CoreService.getCorporation();

            if (vm.corporation && vm.corporation.rcopiaIntegration) {
                Rcopia.getNotificationCount({corporationId: vm.corporation.id}, function (result) {
                    vm.rcopiaNotifications = result;

                    vm.rcopiaTotalNotifications = _.sum(_.map(vm.rcopiaNotifications, 'number'));
                });
            }
        }

        function rcopiaPopup(type) {
            var startupScreen = '';

            switch (type) {
                //Refills and RxChange requests can be accessed by launching to the Message screen
                // (startup_screen = message in the SSO request).
                case 'refill':
                case 'rxchange':
                    startupScreen = "message";
                    break;
                //Pending prescriptions and prescriptions needing signing can be accessed by launching to the Report screen
                // (startup_screen = report in the SSO request).
                case 'rx_pending':
                case 'rx_need_signing':
                    startupScreen = "report";
                    break;
            }

            RcopiaUtils.launchRcopiaPopup(startupScreen, 'limpMode');
        }

        function getNotificationLabel(type) {
            switch (type) {
                //Prescription Report
                case 'rx_pending':
                    return 'Rx Pending';
                case 'rx_need_signing':
                    return 'Rx Sent - Not Signed'

                //Pharmacy Message
                case 'rxchange':
                    return 'Rx Change Requests'
                case 'refill':
                    return 'Rx Renewals Awaiting';
            }
        }

        function findValue(type) {
            var notification = _.find(vm.rcopiaNotifications, ['type', type]);
            return notification ? notification.number : 0;
        }
    }
})();

