(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaLastUpdateDateController', RcopiaLastUpdateDateController);

    RcopiaLastUpdateDateController.$inject = ['$scope', '$state', 'RcopiaLastUpdateDate', 'CoreService', 'Corporation',
        'GenericEntityDatatableService', 'ConfirmationService', '$q', 'Chart',
        '$rootScope', '$uibModal'];

    function RcopiaLastUpdateDateController($scope, $state, RcopiaLastUpdateDate, CoreService, Corporation,
                                            GenericEntityDatatableService, ConfirmationService, $q, Chart,
                                            $rootScope, $uibModal) {
        var vm = this;

        var params = {
            // corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Rcopia Last Update Date',
            entityClassHumanized: 'Rcopia Last Update Date',
            entityStateName: 'rcopia-last-update-date',
            service: RcopiaLastUpdateDate,
            serviceMethod: 'query',
            params: params,
            pagination: 'remote',
            newAction: false,
            // options: [
            //     {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            // ],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'api',
                    title: 'API',
                },
                {
                    title: 'Last Update Date',
                    name: 'lastUpdateDate',
                },
                {
                    title: 'RcopiaID',
                    name: 'rcopiaID'
                },
                {
                    name: 'chartId',
                    title: 'Chart',
                    render: function (entity) {
                        return entity.chart ?
                            entity.chart.firstName + " " +
                            (entity.chart.middleName ? entity.chart.middleName + ' ' : '') +
                            entity.chart.lastName : ""
                    }
                },
                {
                    name: 'mrno',
                    title: 'MrNo',
                    render: function (entity) {
                        return entity.chart ? entity.chart.mrNo : ""
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'api',
                    placeholder: 'API...',
                    type: 'select',
                    values: [
                        'update_allergy',
                        'update_allergy_group',
                        'update_patient',
                        'update_medication',
                        'update_notification',
                        'get_notification_count',
                        'update_prescription',
                        'update_rxchange',
                        'update_prescription_events',
                        'update_rxfill',

                        'send_patient',
                        'send_problem',
                        'send_allergy'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
                {
                    name: 'rcopiaId',
                    placeholder: 'RcopiaID...',
                    type: 'string',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'corporationId',
                    read: function (filter) {
                        return filter.corporationId ? filter.corporationId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        Corporation.query(function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    defaultValue: function () {
                        return CoreService.getCorporation();
                    },
                    placeholder: 'Corporation ...',
                    type: 'select',
                },
                {
                    name: 'start',
                    // title: 'Start Date',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.start ? moment(filter.start).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
                {
                    name: 'end',
                    // title: 'End Date',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.end ? moment(filter.end).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
            ]
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('RcopiaLastUpdateDate').result.then(function () {
                    RcopiaLastUpdateDate.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-last-update-date/update/rcopia-last-update-dialog.html',
                controller: 'RcopiaLastUpdateDateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['RcopiaLastUpdateDate', function (RcopiaLastUpdateDate) {
                        return RcopiaLastUpdateDate.get({id: id}).$promise;
                    }]
                }
            }).result;
        }
    }
})();
