(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Report', Report);

    Report.$inject = ['$resource'];

    function Report($resource) {
        var resourceUrl = 'api/reports/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByFilter': {url: 'api/reports/filter', method: 'GET', isArray: true},
            'getAllReportCategory': {url: 'api/reports/report-categories', method: 'GET', isArray: true},
            'getAllReportFieldsByCategory': {url: 'api/reports/report-fields-by-category/:category', method: 'GET', isArray: true},
            'getAllReportFieldsRequiredByCategory': {url: 'api/reports/report-fields-required-by-category/:category', method: 'GET', isArray: true},
            'getValuesByService': {
                url: 'api/reports/report-condition-service/:facilityId/value/:value',
                method: 'GET',
                isArray: true
            },
            'searchValuesByService': {
                url: 'api/reports/report-condition-service-search/:facilityId/value/:value',
                method: 'GET',
                isArray: true
            },
            'getValuesByEnum': {url: 'api/reports/report-condition-enum/:value', method: 'GET', isArray: true},
            'getReportConditions': {url: 'api/reports/report-conditions/:reportId', method: 'GET', isArray: true}
        });
    }
})
();
