(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DiagnosesDialogController', DiagnosesDialogController);

    DiagnosesDialogController.$inject = ['$timeout', '$scope', 'BUSINESS_ROLES', 'BusinessCredential', '$uibModalInstance',
        'CoreService', 'entity', 'Diagnoses', 'toastr', 'Acuity', 'Icd10Search'];

    function DiagnosesDialogController($timeout, $scope, BUSINESS_ROLES, BusinessCredential, $uibModalInstance,
                                       CoreService, entity, Diagnoses, toastr, Acuity, Icd10Search) {

        var vm = this;

        vm.diagnoses = entity;
        vm.icd10 = [];
        vm.showDelete = false;

        vm.save = save;
        vm.clear = clear;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.getIcd10 = getIcd10;
        vm.confirmDelete = confirmDelete;

        init();

        function init() {
            vm.datePickerOpenStatus = {
                startDate: false,
                endDate: false
            }

            vm.endDateOptions = {
                maxDate: new Date()
            }

            vm.startDateOptions = {}
        }

        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diagnoses.endDate;

            if (vm.diagnoses.endDate) {
                vm.diagnoses.endDate.setMilliseconds(0);
                vm.diagnoses.endDate.setSeconds(0);
            }
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diagnoses.startDate;
            vm.endDateOptions.maxDate = new Date();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            Diagnoses.update(vm.diagnoses, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:diagnosesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete() {
            Diagnoses.delete({id: vm.diagnoses.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

