(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaSsoUrlLogDetailController', RcopiaSsoUrlLogDetailController);

    RcopiaSsoUrlLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$uibModalInstance'];

    function RcopiaSsoUrlLogDetailController($scope, $rootScope, $stateParams, entity, $uibModalInstance) {
        var vm = this;

        vm.entity = entity;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
