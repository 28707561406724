(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaSsoLogController', RcopiaSsoLogController);

    RcopiaSsoLogController.$inject = ['$scope', '$state', 'RcopiaSsoLog', 'CoreService', 'Chart', 'Corporation',
        'GenericEntityDatatableService', '$rootScope', 'GUIUtils', '$uibModal'];

    function RcopiaSsoLogController($scope, $state, RcopiaSsoLog, CoreService, Chart, Corporation,
                                    GenericEntityDatatableService, $rootScope, GUIUtils, $uibModal) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Rcopia SSO Logs',
            entityClassHumanized: 'Rcopia SSO Logs',
            entityStateName: 'rcopia-sso-log',
            service: RcopiaSsoLog,
            serviceMethod: 'query',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'api',
                    title: 'API',
                },
                {
                    title: 'Payload',
                    name: 'payload',
                    render: function (data) {
                        if (data.payload) {
                            return data.payloadSize ? CoreService.bytesToSize(data.payloadSize) : "";
                        } else {
                            return GUIUtils.getStatusTemplate("No", 'danger');
                        }
                    }
                },
                {
                    title: 'Response',
                    name: 'response',
                    render: function (data) {
                        if (data.response) {
                            return data.responseSize ? CoreService.bytesToSize(data.responseSize) : "";
                        } else {
                            return GUIUtils.getStatusTemplate("No", 'danger');
                        }
                    }
                },
                {
                    name: 'responseStatus',
                    title: 'Response Status',
                    render: function (data) {
                        if (data.responseStatus) {
                            if (data.responseStatus == 'error') {
                                return GUIUtils.getStatusTemplate(data.responseStatus, 'danger');
                            } else {
                                return GUIUtils.getStatusTemplate(data.responseStatus, 'success');
                            }
                        } else {
                            return "";
                        }
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                    render: function (data) {
                        if (data.status == 'error') {
                            return GUIUtils.getStatusTemplate(data.status, 'danger');
                        } else {
                            return GUIUtils.getStatusTemplate(data.status, 'success');
                        }
                    }
                },
                {
                    name: 'number',
                    title: 'Number',
                },
                {
                    name: 'entityError',
                    title: 'Errors',
                },
                {
                    name: 'message',
                    title: 'Message',
                },
                {
                    name: 'chartId',
                    title: 'Chart',
                    render: function (entity) {
                        return entity.chart ?
                            entity.chart.firstName + " " +
                            (entity.chart.middleName ? entity.chart.middleName + ' ' : '') +
                            entity.chart.lastName : ""
                    }
                },
                {
                    name: 'mrno',
                    title: 'MrNo',
                    render: function (entity) {
                        return entity.chart ? entity.chart.mrNo : ""
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
                {
                    name: 'createdDate',
                    title: 'Date',
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.amDateFormat) : '-';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    name: 'View Payload',
                    action: function (data) {
                        return viewXmlPayload(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    name: 'View Response',
                    action: function (data) {
                        return viewXmlResponse(data.id);
                    }
                }),
                {
                    name: 'Download Payload',
                    action: function (data) {
                        RcopiaSsoLog.payload({id: data.id})
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-file-text-o',
                    ngIf: function (data) {
                        return data.payload;
                    }
                },
                {
                    name: 'Download Response',
                    action: function (data) {
                        RcopiaSsoLog.response({id: data.id});
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-file-text-o',
                    ngIf: function (data) {
                        return data.responseSize;
                    }
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'api',
                    placeholder: 'API...',
                    type: 'select',
                    values: [
                        'update_allergy',
                        'update_allergy_group',
                        'update_patient',
                        'update_medication',
                        'update_notification',
                        'get_notification_count',
                        'update_prescription',
                        'update_rxchange',
                        'update_prescription_events',
                        'update_rxfill',

                        'send_patient',
                        'send_problem',
                        'send_allergy'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
                {
                    name: 'number',
                    placeholder: 'Number...',
                    type: 'number'
                },
                {
                    name: 'responseStatus',
                    placeholder: 'Response Status...',
                    type: 'select',
                    values: [
                        'ok', 'error'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
                {
                    name: 'status',
                    placeholder: 'Status...',
                    type: 'select',
                    values: [
                        'ok', 'error'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
                {
                    name: 'rcopiaId',
                    placeholder: 'RcopiaID...',
                    type: 'string',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'start',
                    // title: 'Start Date',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.start ? moment(filter.start).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
                {
                    name: 'end',
                    // title: 'End Date',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.end ? moment(filter.end).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
                {
                    name: 'corporationId',
                    read: function (filter) {
                        return filter.corporationId ? filter.corporationId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        Corporation.query(function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    defaultValue: function () {
                        return CoreService.getCorporation();
                    },
                    placeholder: 'Corporation ...',
                    type: 'select',
                },
            ]
        }

        function viewXmlPayload(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-sso-log/detail/rcopia-sso-log-detail.html',
                controller: 'RcopiaSsoLogDetailController',
                controllerAs: 'vm',
                backdrop: true,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['RcopiaSsoLog', function (RcopiaSsoLog) {
                        return RcopiaSsoLog.payloadStr({id: id}).$promise;
                    }],
                    data: {
                        title: "View Payload"
                    }
                }
            }).result;
        }

        function viewXmlResponse(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-sso-log/detail/rcopia-sso-log-detail.html',
                controller: 'RcopiaSsoLogDetailController',
                controllerAs: 'vm',
                backdrop: true,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['RcopiaSsoLog', function (RcopiaSsoLog) {
                        return RcopiaSsoLog.responseStr({id: id}).$promise;
                    }],
                    data: {
                        title: "View Response"
                    }
                }
            }).result;
        }
    }
})();
