/**
 * Created by PpTMUnited on 5/23/2017.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('udtMenuOptionsBottom', MenuOptionsBottom);

    MenuOptionsBottom.$inject = ['$rootScope', 'ACTIONS', 'CoreService'];

    function MenuOptionsBottom($rootScope, ACTIONS, CoreService) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-menu-options-bottom/udt-menu-options-bottom.html',
            scope: {
                chart: '=',
                access: '='
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope) {
            $scope.corporation = CoreService.getCorporation();
            $scope.accessCloseChart = $scope.access[ACTIONS.ACTION_PATIENT_CLOSE_CHART] ? $scope.access[ACTIONS.ACTION_PATIENT_CLOSE_CHART].roles.join() : null;
            $scope.chartStatus = $scope.chart.status;

            $scope.sendEvent = function(alert) {
                var event = 'bluebookApp:'+alert;
                $rootScope.$broadcast(event);
            }
        }
    }
})();
