(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('RcopiaSsoUrlLog', RcopiaSsoUrlLog);

    RcopiaSsoUrlLog.$inject = ['$resource', 'DataUtils'];

    function RcopiaSsoUrlLog ($resource, DataUtils) {
        var resourceUrl =  'api/rcopia-sso-url-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, url: 'api/rcopia-sso-url-logs/filter'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'url': {
                url: 'api/rcopia-sso-url-logs/url/:id',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'urlStr': {
                url: 'api/rcopia-sso-url-logs/url/str/:id',
                method: 'GET',
                transformResponse: function (data) {
                    return {data: data};
                }
            },
        });
    }
})();
