(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaSsoUrlLogController', RcopiaSsoUrlLogController);

    RcopiaSsoUrlLogController.$inject = ['$scope', '$state', 'RcopiaSsoUrlLog', 'CoreService', '$uibModal', 'Chart',
        'GenericEntityDatatableService', '$rootScope', 'Corporation', 'GUIUtils'];

    function RcopiaSsoUrlLogController($scope, $state, RcopiaSsoUrlLog, CoreService, $uibModal, Chart,
                                       GenericEntityDatatableService, $rootScope, Corporation, GUIUtils) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Rcopia SSO Url Logs',
            entityClassHumanized: 'Rcopia SSO Url Logs',
            entityStateName: 'rcopia-sso-url-log',
            service: RcopiaSsoUrlLog,
            serviceMethod: 'query',
            params: params,
            pagination: 'remote',
            newAction: false,
            // options: [
            //     {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            // ],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'limpMode',
                    title: 'Limp Mode',
                    render: function (entity) {
                        return GUIUtils.colorHtmlYesNo(entity.limpMode);
                    }
                },
                {
                    name: 'employee',
                    title: 'Employee',
                    render: function(data) {
                        return data.employee.lastName + ', ' + data.employee.firstName;
                    }
                },
                {
                    name: 'chartId',
                    title: 'Chart',
                    render: function (entity) {
                        return entity.chart ?
                            entity.chart.firstName + " " +
                            (entity.chart.middleName ? entity.chart.middleName + ' ' : '') +
                            entity.chart.lastName : ""
                    }
                },
                {
                    name: 'mrno',
                    title: 'MrNo',
                    render: function (entity) {
                        return entity.chart ? entity.chart.mrNo : ""
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
                {
                    name: 'createdDate',
                    title: 'Date',
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.amDateFormat) : '-';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    name: 'View Url',
                    action: function (data) {
                        return viewUrl(data.id);
                    }
                }),
                {
                    name: 'Download Url',
                    action: function (data) {
                        RcopiaSsoUrlLog.url({id: data.id})
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-file-text-o'
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'rcopiaId',
                    placeholder: 'RcopiaID...',
                    type: 'string',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'start',
                    // title: 'Start Date',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.start ? moment(filter.start).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
                {
                    name: 'end',
                    // title: 'End Date',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.end ? moment(filter.end).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
                {
                    name: 'corporationId',
                    read: function (filter) {
                        return filter.corporationId ? filter.corporationId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        Corporation.query(function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    defaultValue: function () {
                        return CoreService.getCorporation();
                    },
                    placeholder: 'Corporation ...',
                    type: 'select',
                },
            ]
        }

        function viewUrl(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/rcopia-sso-url-log/url/rcopia-sso-url-log-detail.html',
                controller: 'RcopiaSsoUrlLogDetailController',
                controllerAs: 'vm',
                backdrop: true,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['RcopiaSsoUrlLog', function (RcopiaSsoUrlLog) {
                        return RcopiaSsoUrlLog.urlStr({id: id}).$promise;
                    }]
                }
            }).result;
        }
    }
})();
