(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcopiaSsoLogDetailController', RcopiaSsoLogDetailController);

    RcopiaSsoLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$uibModalInstance',
        'data'];

    function RcopiaSsoLogDetailController($scope, $rootScope, $stateParams, entity, $uibModalInstance,
                                          data) {
        var vm = this;
        vm.data = data;

        vm.clear = clear;

        init();

        function init() {
            vm.beautifiedXmlText = new XmlBeautify().beautify(entity.data,
                {
                    indent: "",  //indent pattern like white spaces
                    useSelfClosingElement: true //true:use self-closing element when empty element.
                });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
